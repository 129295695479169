import React, { useState, useEffect } from 'react'
import { Container, Row, Col, ListGroup } from 'react-bootstrap'
import Card from '../../../components/Card'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router-dom';
import axios from 'axios'; // Import Axios
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';


// img
import product1 from '../../../assets/images/products/1.jpg'
import product6 from '../../../assets/images/products/6.jpg'
import product8 from '../../../assets/images/products/8.jpg'


const Orderdetails = ({ user }) => {
    let history = useHistory()
    const { orderId } = useParams();
    const [orderData, setOrderData] = useState({});
    const [invoiceData, setInvoiceData] = useState({});
    const [orderItemsData, setOrderItemsData] = useState([]);
    const [customerData, setCustomerData] = useState({});
    const [customerCountryData, setCustomerCountryData] = useState({});
    const [customerStateData, setCustomerStateData] = useState({});

    const token = user.user.user.token
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_URL}/companies/${user.user.user.companyDetails.id}/orders/${orderId}`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                if (response.data.status) {
                    setOrderData(response.data.orderData);
                    setInvoiceData(response.data.orderData.invoice);
                    setOrderItemsData(response.data.orderData.order_items);
                    setCustomerData(response.data.orderData.customer);
                    setCustomerCountryData(response.data.orderData.customer.country);
                    setCustomerStateData(response.data.orderData.customer.state);

                }

            } catch (error) {
                console.error('Error fetching customer details:', error);
            }
        };

        fetchData();
    }, [orderId, token, user.user.user.companyDetails.id]);
    const getStatusColor = (status) => {
        switch (status) {
            case 'pending':
                return '#f39c12'; // Yellow
            case 'processing':
                return '#3498db'; // Blue
            case 'completed':
                return '#3cb72c'; // Green
            case 'canceled':
                return '#e74c3c'; // Red
            default:
                return '#000000'; // Black (Fallback color)
        }
    };
    return (
        <>
            <Container fluid>
                <Row>
                    <Col lg="12">
                        <div className="d-flex flex-wrap align-items-center justify-content-between mb-3">
                            <div className="d-flex align-items-center justify-content-between">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb p-0 mb-0">
                                        <li className="breadcrumb-item"><Link to="/orders">Orders</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">Order Details</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </Col>
                    <Col lg="12" className="mb-3">
                        <div className="d-flex justify-content-between align-items-center">
                            <h4 className="font-weight-bold">Order Details</h4>
                            <Link className="btn btn-primary btn-sm" to="/orders-new">
                                <svg xmlns="http://www.w3.org/2000/svg" className="mr-2" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                                </svg>
                                Generate Invoice
                            </Link>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg="4">
                        <Card>
                            <ListGroup as="ul" className="list-group-flush">
                                <ListGroup.Item as="li" className="p-3">
                                    <h5 className="font-weight-bold pb-2">Order Info</h5>
                                    <div className="table-responsive">
                                        <table className="table table-borderless mb-0">
                                            <tbody>
                                                <tr className="white-space-no-wrap">
                                                    <td className="text-muted pl-0">
                                                        ID
                                                    </td>
                                                    <td>
                                                        {orderData.number}
                                                    </td>
                                                </tr>
                                                <tr className="white-space-no-wrap">
                                                    <td className="text-muted pl-0">
                                                        Date
                                                    </td>
                                                    <td>
                                                        {orderData.date}
                                                    </td>
                                                </tr>
                                                <tr className="white-space-no-wrap">
                                                    <td className="text-muted pl-0">
                                                        Payment Method
                                                    </td>
                                                    <td>
                                                        Credit Card
                                                    </td>
                                                </tr>
                                                <tr className="white-space-no-wrap">
                                                    <td className="text-muted pl-0">
                                                        Invoice
                                                    </td>
                                                    <td className="text-primary">
                                                        <Link to={"/invoice-view/" + invoiceData.id}>
                                                            {invoiceData.invoice_number}
                                                        </Link>
                                                    </td>
                                                </tr>
                                                <tr className="white-space-no-wrap">
                                                    <td className="text-muted pl-0">
                                                        Status
                                                    </td>
                                                    <td>
                                                        <p className={`mb-0 ${getStatusColor(orderData.status)} font-weight-bold d-flex justify-content-start align-items-center`}>
                                                            <small><svg className="mr-2" xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 24 24" fill="none">
                                                                <circle cx="12" cy="12" r="8" fill={getStatusColor(orderData.status)}></circle></svg>
                                                            </small>{orderData.status}
                                                        </p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </ListGroup.Item>
                                <ListGroup.Item as="li" className="p-3">
                                    <h5 className="font-weight-bold pb-2">Customer Details</h5>
                                    <div className="table-responsive">
                                        <table className="table table-borderless mb-0">
                                            <tbody>
                                                <tr className="white-space-no-wrap">
                                                    <td className="text-muted pl-0">
                                                        Name
                                                    </td>
                                                    <td>
                                                        {customerData.name}
                                                    </td>
                                                </tr>
                                                <tr className="white-space-no-wrap">
                                                    <td className="text-muted pl-0">
                                                        Email
                                                    </td>
                                                    <td>
                                                        {customerData.email}
                                                    </td>
                                                </tr>
                                                <tr className="white-space-no-wrap">
                                                    <td className="text-muted pl-0">
                                                        Phone
                                                    </td>
                                                    <td>
                                                        {customerData.phone}
                                                    </td>
                                                </tr>
                                                <tr className="white-space-no-wrap">
                                                    <td className="text-muted pl-0">
                                                        Country
                                                    </td>
                                                    <td>
                                                        {customerCountryData.name}
                                                    </td>
                                                </tr>
                                                <tr className="white-space-no-wrap">
                                                    <td className="text-muted pl-0">
                                                        State/Region
                                                    </td>
                                                    <td>
                                                        {customerStateData.name}
                                                    </td>
                                                </tr>
                                                <tr className="white-space-no-wrap">
                                                    <td className="text-muted pl-0">
                                                        Address
                                                    </td>
                                                    <td>
                                                        {customerData.address}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </ListGroup.Item>
                            </ListGroup>
                        </Card>
                    </Col>
                    <Col lg="8">
                        <Card>
                            <ListGroup as="ul" className="list-group-flush">
                                <ListGroup.Item as="li" className="p-3">
                                    <h5 className="font-weight-bold">Order Items</h5>
                                </ListGroup.Item>
                                <ListGroup.Item as="li" className="p-0">
                                    <div className="table-responsive">
                                        <table className="table mb-0">
                                            <thead>
                                                <tr className="text-muted">
                                                    <th scope="col">Product</th>
                                                    <th scope="col" className="text-right">Quantity</th>
                                                    <th scope="col" className="text-right">Price</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    orderItemsData.map((item) => (

                                                        <tr key={item.id}>
                                                            <td>
                                                                <div className="active-project-1 d-flex align-items-center mt-0 ">
                                                                    <div className="h-avatar is-medium">
                                                                        {item.product.images ? (
                                                                            <img className="avatar rounded" alt="product-icon" src={process.env.REACT_APP_BACKEND_URL + item.product.images} />
                                                                        ) : (
                                                                            <i className="fas fa-image fa-2x"></i>
                                                                        )}

                                                                    </div>
                                                                    <div className="data-content">
                                                                        <div>
                                                                            <span className="font-weight-bold">{item.product.name}</span>
                                                                        </div>
                                                                        <p className="m-0 mt-1">
                                                                            {item.product.product_description}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td className="text-right">
                                                                {item.quantity}
                                                            </td>
                                                            <td className="text-right">
                                                                {orderData.currency.symbol}{item.price}
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </ListGroup.Item>
                                <ListGroup.Item as="li" className="p-3">
                                    <div className="d-flex justify-content-end">
                                        Total: <p className="ml-2 mb-0 font-weight-bold">$1,237.44</p>
                                    </div>
                                </ListGroup.Item>
                            </ListGroup>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Orderdetails;