import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Button, Form, OverlayTrigger, Tooltip, Spinner } from 'react-bootstrap';
import Card from '../../../components/Card';
import { Link } from 'react-router-dom';
import axios from 'axios'; // Import Axios
import Swal from 'sweetalert2';
import $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4/js/dataTables.bootstrap4.min.js';
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import './tablesearch.css';

const Transaction = ({ user }) => {

    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        const token = user.user.user.token;

        // Fetch transactions data using Axios
        axios
            .get(process.env.REACT_APP_API_URL + '/companies/' + user.user.user.companyDetails.id + '/transactions', {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            })
            .then((response) => {
                setTransactions(response.data.transactionData); // Assuming API response is an array of transactions
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching transactions:', error);
                setLoading(false);
            });
    }, [user]);
    useEffect(() => {

        // Initialize the DataTable when categories change
        if (!loading && transactions.length > 0) {
            if (!$.fn.DataTable.isDataTable(dataTableRef.current)) {
                // If not initialized, initialize the DataTable
                $(dataTableRef.current).DataTable({
                    columns: [
                        { data: 'id', title: 'ID' },
                        { data: 'customer', title: 'Customer' },
                        { data: 'order_id', title: 'Order Id' },
                        { data: 'invoice_id', title: 'Invoice Id' },
                        { data: 'merchant_id', title: 'Merchant Id' },
                        { data: 'amount', title: 'Amount' },
                        { data: 'status', title: 'Status' },
                    ],
                    order: [[0, 'desc']],
                });
            }
        }
    }, [transactions, loading]);
    const dataTableRef = useRef(null);
    const getStatusColor = (status) => {
        switch (status) {

            case 'completed':
                return '#3cb72c'; // Green
            case 'not_completed':
                return '#e74c3c'; // Red
            default:
                return '#000000'; // Black (Fallback color)
        }
    };
    return (
        <>
            <Container fluid>
                <Row>

                    <Col lg="12">
                        <div className="d-flex flex-wrap align-items-center justify-content-between my-schedule mb-4">
                            <div className="d-flex align-items-center justify-content-between">
                                <h4 className="font-weight-bold">All Transactions</h4>
                            </div>
                            <div className="create-workform">
                                <div className="d-flex flex-wrap align-items-center justify-content-between">
                                    <div className="modal-product-search d-flex">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Row>
                            <Col lg="12">
                                <Card className="card-block card-stretch">
                                    <Card.Body className="p-0">
                                        <div className="d-flex justify-content-between align-items-center p-3">
                                            <h5 className="font-weight-bold">Transactions List</h5>

                                        </div>
                                        <div className="table-responsive">
                                            {loading ? (
                                                <div className="text-center mb-5">
                                                    <Spinner animation="border" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </Spinner>
                                                </div>
                                            ) : (
                                                <table ref={dataTableRef} className="table data-table mb-0">
                                                    <thead className="table-color-heading">
                                                        <tr className="text-light">
                                                            <th scope="col">ID</th>
                                                            <th scope="col">Customer</th>
                                                            <th scope="col">Order ID</th>
                                                            <th scope="col">Invoice ID</th>
                                                            <th scope="col">Merchant ID</th>
                                                            <th scope="col">Amount</th>
                                                            <th scope="col">Status</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {transactions.map((transaction) => (
                                                            <tr key={transaction.id}>
                                                                <td>{transaction.id}</td>
                                                                <td>{transaction.customer.name}</td>
                                                                <td>{transaction.order_id}</td>
                                                                <td>{transaction.invoice_id}</td>
                                                                <td>{transaction.merchant_transaction_id}</td>
                                                                <td>{transaction.currency.symbol}{transaction.payment_amount}</td>
                                                                <td>
                                                                    <p className={`mb-0 ${getStatusColor(transaction.transaction_status)} font-weight-bold d-flex justify-content-start align-items-center`}>
                                                                        <small><svg className="mr-2" xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 24 24" fill="none">
                                                                            <circle cx="12" cy="12" r="8" fill={getStatusColor(transaction.transaction_status)}></circle></svg>
                                                                        </small>{transaction.transaction_status}
                                                                    </p></td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            )}
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </>
    )

}

export default Transaction;