import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Form, OverlayTrigger, Tooltip, Spinner, Button } from 'react-bootstrap'
import Card from '../../../components/Card'
import { Link } from 'react-router-dom'
import axios from 'axios';
import Swal from 'sweetalert2';
import $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4/js/dataTables.bootstrap4.min.js';
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import Select from 'react-select';
import './custome-select.css'
import './tablesearch.css';

const Product = ({ user }) => {

    const [products, setProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');
    const [displayReorderProducts, setDisplayReorderProducts] = useState(false);

    const token = user.user.user.token
    useEffect(() => {
        fetchProducts();
    }, []);
    useEffect(() => {

        // Initialize the DataTable when categories change
        if (!isLoading && products.length > 0) {
            if (!$.fn.DataTable.isDataTable(dataTableRef.current)) {
                // If not initialized, initialize the DataTable
                $(dataTableRef.current).DataTable({
                    columns: [
                        { data: 'id', title: 'ID' },
                        { data: 'name', title: 'Name' },
                        { data: 'company', title: 'Company' },
                        { data: 'category', title: 'Category' },
                        { data: 'code', title: 'Code' },
                        { data: 'status', title: 'Status' },
                        { data: 'price', title: 'Price' },
                        { data: 'stock', title: 'Stock Quantity' },
                        { data: '', title: 'Action' },
                    ],
                    order: [[0, 'desc']],
                });
            }
        }

        $(dataTableRef.current).on('click', '.delete-button', function () {
            const productId = $(this).data('product-id');
            handleDelete(productId);
        });
    }, [products, isLoading]);
    const dataTableRef = useRef(null);
    const fetchProducts = () => {

        axios.get(process.env.REACT_APP_API_URL + '/companies/' + user.user.user.companyDetails.id + '/products', {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        })
            .then((response) => {
                const fetchedProducts = response.data;
                if (fetchedProducts.status) {
                    setProducts(fetchedProducts.data);
                    setIsLoading(false);
                }

            })
            .catch((error) => {
                console.error('Error fetching products:', error);
                setIsLoading(false);
            }).finally(() => {
                setIsLoading(false); // Set isLoading to false after fetching (success or error)
            });
    };

    const fetchReOrderProducts = () => {

        const data = {
            companyId: user.user.user.companyDetails.id
        }
        axios.post(process.env.REACT_APP_API_URL + '/reorder-products', data, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        })
            .then((response) => {
                const fetchedProducts = response.data;
                if (fetchedProducts.status) {
                    setProducts(fetchedProducts.data);
                    setIsLoading(false);
                    setDisplayReorderProducts(true);
                }

            })
            .catch((error) => {
                console.error('Error fetching products:', error);
                setIsLoading(false);
            }).finally(() => {
                setIsLoading(false); // Set isLoading to false after fetching (success or error)
            });
    };

    const handleDelete = (productId) => {

        Swal.fire({
            title: 'Delete Customer',
            text: 'Are you sure you want to delete this customer?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, delete it',
        }).then((result) => {
            if (result.isConfirmed) {

                axios
                    .delete(process.env.REACT_APP_API_URL + `/companies/${user.user.user.companyDetails.id}/products/${productId}`, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    })
                    .then((response) => {
                        console.log(response.data);
                        if (response.data.status) {

                            Swal.fire('Done!', response.data.message, 'success').then(() => {
                                setProducts(prevProducts => prevProducts.filter(product => product.id !== productId));
                            });
                        } else {
                            Swal.fire('Ooopsss!', response.data.message, 'error');
                        }
                    })
                    .catch((error) => {
                        Swal.fire('Ooopsss!', error.response.data.message, 'error');
                    });
            }
        });
    };

    const getStatusName = (quantity, reorderLevel) => {
        if (quantity > reorderLevel) {
            return 'In Stock'
        }
        else if (quantity <= 0) {
            return "Out of Stock";
        } else if (quantity <= reorderLevel) {
            return "Low Stock";
        } else {
            return "Out of Stock";
        }
    };

    const getColorCode = (name) => {
        if (name == "In Stock") {
            return '#3cb72c'
        }
        else if (name == "Out of Stock") {
            return "#e74c3c";
        } else if (name == "Low Stock") {
            return "#FFA500";
        } else {
            return "#e74c3c";
        }
    };

    return (
        <>
            <Container fluid>
                <Row>

                    <Col lg="12">
                        <div className="d-flex flex-wrap align-items-center justify-content-between my-schedule mb-4">
                            <div className="d-flex align-items-center justify-content-between">
                                <h4 className="font-weight-bold">Product</h4>
                            </div>
                            <div className="create-workform">
                                <div className="d-flex flex-wrap align-items-center justify-content-between">
                                    <div className="modal-product-search d-flex">
                                        <Link to="/products-add" className="btn btn-primary position-relative d-flex align-items-center justify-content-between">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="mr-2" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                            </svg>
                                            Add Product
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Row>
                            <Col lg="12">
                                <Card className="card-block card-stretch">
                                    <Card.Body className="p-0">
                                        <div className="d-flex justify-content-between align-items-center p-3">
                                            <h5 className="font-weight-bold">Products List</h5>

                                        </div>
                                        <div className="table-responsive">
                                            {isLoading ? (
                                                <div className="text-center mb-5">
                                                    <Spinner animation="border" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </Spinner>
                                                </div>
                                            ) : (
                                                <table ref={dataTableRef} className="table data-table mb-0">
                                                    <thead className="table-color-heading">
                                                        <tr className="text-light">
                                                            <th scope="col">
                                                                <label className="text-muted m-0" >ID</label>
                                                            </th>
                                                            <th scope="col">
                                                                <label className="text-muted m-0" >Name</label>
                                                            </th>
                                                            <th scope="col">
                                                                <label className="text-muted m-0" >Company</label>
                                                            </th>
                                                            <th scope="col">
                                                                <label className="text-muted mb-0" >Category</label>

                                                            </th>
                                                            <th scope="col">
                                                                <label className="text-muted mb-0" >Code</label>

                                                            </th>
                                                            <th scope="col">
                                                                <label className="text-muted mb-0" >Inventory Status</label>

                                                            </th>
                                                            <th scope="col" className="text-right">
                                                                <label className="text-muted mb-0" >Price</label>
                                                                {/* <input type="text" className="form-control mt-2" id="text2" aria-describedby="textHelp" placeholder="Price">  */}
                                                            </th>
                                                            <th scope="col">
                                                                <label className="text-muted mb-0" >Stock Quantity</label>
                                                                {/* <input type="text" className="form-control mt-2" id="text3" aria-describedby="textHelp" placeholder="Quantity"> */}
                                                            </th>

                                                            <th scope="col" className="text-right">
                                                                <span className="text-muted" >Action</span>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            products
                                                                .filter(item =>
                                                                    item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                                                                    item.company_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                                                                    item.barcode !== null && item.barcode.includes(searchQuery) ||
                                                                    item.product_code.includes(searchQuery)
                                                                )
                                                                .map((item) => (
                                                                    <tr key={item.id} className="white-space-no-wrap">
                                                                        <td>{item.id}</td>
                                                                        <td className="">
                                                                            <div className="active-project-1 d-flex align-items-center mt-0 ">
                                                                                <div className="h-avatar is-medium">
                                                                                    {item.product_image ? (
                                                                                        <img className="avatar rounded" alt="product-icon" src={process.env.REACT_APP_BACKEND_URL + item.product_image} />
                                                                                    ) : (
                                                                                        <i className="fas fa-image fa-2x"></i>
                                                                                    )}
                                                                                </div>
                                                                                <div className="data-content">
                                                                                    <div>
                                                                                        <span className="font-weight-bold">{item.name}</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td>{item.company_name}</td>

                                                                        <td>
                                                                            {item.category_name}
                                                                        </td>
                                                                        <td>
                                                                            {item.product_code}
                                                                        </td>
                                                                        <td>
                                                                            <p className={`mb-0 ${getColorCode(getStatusName(item.stock_quantity, item.reorder_level))} font-weight-bold d-flex justify-content-start align-items-center`}>
                                                                                <small>
                                                                                    <svg className="mr-2" xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 24 24" fill="none">
                                                                                        <circle cx="12" cy="12" r="8" fill={getColorCode(getStatusName(item.stock_quantity, item.reorder_level))}></circle>
                                                                                    </svg>
                                                                                </small>
                                                                                {getStatusName(item.stock_quantity, item.reorder_level)}
                                                                            </p>
                                                                        </td>
                                                                        <td>
                                                                            {item.currency_name}{item.price}
                                                                        </td>
                                                                        <td>
                                                                            {item.stock_quantity}
                                                                        </td>

                                                                        <td>
                                                                            <div className="d-flex justify-content-end align-items-center">
                                                                                <OverlayTrigger placement="top" overlay={<Tooltip>Edit</Tooltip>} >
                                                                                    <Link className="" to={`/products-edit/${item.id}`}>
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" className="text-secondary mx-4" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                                                                                        </svg>
                                                                                    </Link>
                                                                                </OverlayTrigger>
                                                                                <OverlayTrigger placement="top" overlay={<Tooltip>Delete</Tooltip>} >
                                                                                    <button className="badge bg-danger delete-button" href="#" data-product-id={item.id} onClick={() => handleDelete(item.id)}>
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                                                                        </svg>
                                                                                    </button>

                                                                                </OverlayTrigger>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                ))

                                                        }
                                                    </tbody>
                                                </table>
                                            )}
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Product;
