import React, { useState } from 'react';
import { Container, Col, Row, Form, Button } from 'react-bootstrap'
import Card from '../../../components/Card'
import { connect } from "react-redux";
import { getDarkMode } from '../../../store/mode'
import axios from 'axios';
import Swal from 'sweetalert2';

//img
import logo from '../../../assets/images/logo.png';
import darklogo from '../../../assets/images/q-software-logo.jpeg';



import { useHistory } from 'react-router-dom'

function mapStateToProps(state) {
   return {
      darkMode: getDarkMode(state)
   };
}


const RecoverPassword = (props) => {
   let history = useHistory()
   const [email, setEmail] = useState([]);
   const [isSaving, setIsSaving] = useState(false);
   const handleResetPassword = async (e) => {
      e.preventDefault();
      try {
         setIsSaving(true);
         // Send a request to your API with the user's email address
         const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/forgot-password`,
            { email }
         );
         if (response.data.status) {
            history.push('/auth/confirm-mail');
         } else {
            Swal.fire("Ooops!", response.data.message, 'error');
         }
      } catch (error) {
         Swal.fire("Ooops!", error.message, 'error');
      }
      finally {
         setIsSaving(false);
      }
   };

   return (
      <>
         <section className="login-content">
            <Container className="h-100">
               <Row className="align-items-center justify-content-center h-100">
                  <Col md="5">
                     <Card className="p-5">
                        <Card.Body>
                           <div className="auth-logo">
                              <img src={logo} className={`img-fluid  rounded-normal  ${!props.darkMode ? 'd-none' : ''}`} alt="logo" />
                              <img src={darklogo} className={`img-fluid  rounded-normal  ${props.darkMode ? 'd-none' : ''}`} alt="logo" />
                           </div>
                           <h3 className="mb-3 text-center">Reset Password</h3>
                           <p className="text-center small text-secondary mb-3">You can reset your password here</p>

                           <Row>
                              <Col lg="12">
                                 <Form.Group>
                                    <Form.Label className="text-secondary">Email</Form.Label>
                                    <Form.Control
                                       type="email"
                                       placeholder="Enter Email"
                                       // Bind the input value to a component state (e.g., setEmail)
                                       // Example:
                                       value={email}
                                       onChange={(e) => setEmail(e.target.value)}
                                    />
                                 </Form.Group>
                              </Col>
                           </Row>
                           <Button type="button"
                              variant="btn btn-primary btn-block"
                              onClick={(e) => handleResetPassword(e)}
                              disabled={isSaving}
                           >
                              {isSaving ? 'Resetting...' : 'Reset Password'}
                           </Button>


                        </Card.Body>
                     </Card>
                  </Col>
               </Row>
            </Container>
         </section>
      </>
   )
}

export default connect(mapStateToProps)(RecoverPassword)