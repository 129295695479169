import { Switch, Route, useLocation } from 'react-router-dom'
import { TransitionGroup, CSSTransition } from "react-transition-group";
import React from 'react';

//main
import Dashbord from '../views/backend/Main/Dashbord'

// Customers
import Customer from '../views/backend/Main/Customer';
import Customeradd from '../views/backend/Main/Customeradd';
import Customerview from '../views/backend/Main/Cutomerview';
import Customeredit from '../views/backend/Main/Customeredit';

// Product
import Product from '../views/backend/Main/Product';
import Productadd from '../views/backend/Main/Productadd';
import Productedit from '../views/backend/Main/Productedit';

// order
import Order from '../views/backend/Main/Order';
import Ordernew from '../views/backend/Main/Ordernew';
import Orderdetails from '../views/backend/Main/Orderdetails';
import Orderedit from '../views/backend/Main/Orderedit';


//App
import UserProfile from '../views/backend/App/UserManagement/UserProfile'
import UserAdd from '../views/backend/App/UserManagement/UserAdd'
import UserList from '../views/backend/App/UserManagement/UserList'
import UserPrivacySetting from '../views/backend/App/UserManagement/UserPrivacySetting'
import UserAccountSettingList from '../views/backend/App/UserManagement/UserAccountSetting'
import UserProfileEdit from '../views/backend/App/UserManagement/UserProfileEdit'

//Chart
import ApexChart from '../views/backend/Chart/ApexChart'
import Invoice from '../views/backend/pages/Invoice'
import Invoiceview from '../views/backend/pages/Invoiceview';

//Companies
import Companies from '../views/backend/Main/Companies';
import Companiesedit from '../views/backend/Main/Companiesedit';
import Companiesadd from '../views/backend/Main/Companiesadd';
import Companiesview from '../views/backend/Main/Companiesview';

//Categories
import Categories from '../views/backend/Main/Categories';
import Categoriesadd from '../views/backend/Main/Categoriesadd';
import Categoriesedit from '../views/backend/Main/Categoriesedit';


//Transactions
import Transaction from '../views/backend/Main/Transaction';



const Layout1Route = (user) => {

    let location = useLocation();

    return (
        <TransitionGroup>
            <CSSTransition
                key={location.key}
                classNames="fade"
                timeout={300}
            >
                <Switch location={location}>
                    <Route path="/dashboard" exact render={(props) => <Dashbord {...props} user={user} />} />

                    {/* App */}
                    <Route path="/user-profile" render={(props) => <UserProfile {...props} user={user} />} />

                    <Route path="/user-add" component={UserAdd} />
                    <Route path="/user-list" component={UserList} />
                    <Route path="/user-profile-edit" render={(props) => <UserProfileEdit {...props} user={user} />} />

                    {/* Chart */}
                    <Route path="/chart-apex" component={ApexChart} />
                    {/* Extrapages */}
                    <Route path="/invoices" render={(props) => <Invoice {...props} user={user} />} />
                    <Route path="/invoices-view/:invoiceId"><Invoiceview user={user} /></Route>
                    {/*Customer*/}
                    <Route path="/customers" render={(props) => <Customer {...props} user={user} />} />
                    <Route path="/customers-add" render={(props) => <Customeradd {...props} user={user} />} />
                    <Route path="/customers-view/:customerId" render={(props) => <Customerview {...props} user={user} />} />
                    <Route path="/customers-edit/:customerId"><Customeredit user={user} /></Route>

                    {/* Product */}
                    <Route path="/products" render={(props) => <Product {...props} user={user} />} />
                    <Route path="/products-add" render={(props) => <Productadd {...props} user={user} />} />
                    <Route path="/products-edit/:productId"><Productedit user={user} /></Route>

                    {/* Categories */}
                    <Route path="/categories" render={(props) => <Categories {...props} user={user} />} />
                    <Route path="/categories-add" render={(props) => <Categoriesadd {...props} user={user} />} />
                    <Route path="/categories-edit/:categoryId"><Categoriesedit user={user} /></Route>

                    {/* Order */}
                    <Route path="/orders" render={(props) => <Order {...props} user={user} />} />
                    <Route path="/orders-new" render={(props) => <Ordernew {...props} user={user} />} />
                    <Route path="/orders-details/:orderId" render={(props) => <Orderdetails {...props} user={user} />} />
                    <Route path="/orders-edit/:orderId" render={(props) => <Orderedit {...props} user={user} />} />

                    {/* Transactions */}
                    <Route path="/transactions" render={(props) => <Transaction {...props} user={user} />} />

                    {/* Companies */}

                    <Route path="/companies" render={(props) => <Companies {...props} user={user} />} />
                    <Route path="/company-edit/:companyId"><Companiesedit user={user} /></Route>
                    <Route path="/companies-add" render={(props) => <Companiesadd {...props} user={user} />} />
                    <Route path="/company-view/:companyId"><Companiesview user={user} /></Route>

                </Switch>
            </CSSTransition>
        </TransitionGroup>
    )
}

export default Layout1Route