import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Nav, Tab, Form, Button } from 'react-bootstrap'
import Card from '../../../../components/Card'
import { Link } from 'react-router-dom'
import { ChromePicker } from 'react-color';
import '../../Main/colorPicker.css';
import Swal from 'sweetalert2';
import axios from 'axios'; // Import Axios
//img 
import user1 from '../../../../assets/images/user/1.jpg'

const UserProfileEdit = ({ user }) => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [colorThem, setColorThem] = useState('');
    const [logo, setLogo] = useState(null);
    const [logoPreview, setLogoPreview] = useState(null);
    const [showColorPicker, setShowColorPicker] = useState(false);
    const [userName, setUserName] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [userPhone, setUserPhone] = useState('');
    const [userImage, setUserImage] = useState(null);
    const [userImagePreview, setUserImagePreview] = useState(null);
    const [isUpdating, setIsUpdating] = useState(false);
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordErrors, setPasswordErrors] = useState({});
    const token = user.user.user.token;
    const currentUserData = user.user.user.user;
    const currentCompanyData = user.user.user.companyDetails;


    useEffect(() => {
        setName(currentCompanyData.name);
        setEmail(currentCompanyData.email);
        setPhone(currentCompanyData.phone);
        setColorThem(currentCompanyData.color_them || '#ffffff');
        setLogo(currentCompanyData.logo);
        if (currentCompanyData.logo) {
            setLogoPreview(process.env.REACT_APP_BACKEND_URL + currentCompanyData.logo);
        }
        setUserName(currentUserData.name);
        setUserEmail(currentUserData.email || '');
        setUserPhone(currentUserData.phone || '');
        // You might not want to set the userPassword here for security reasons
        setUserImage(null); // Clear previously selected user image
        if (currentUserData.image) {
            setUserImagePreview(process.env.REACT_APP_BACKEND_URL + currentUserData.image);
        }
    }, [currentUserData, currentCompanyData]);


    const toggleColorPicker = () => {
        if (colorThem !== null) {
            setShowColorPicker(!showColorPicker);
        }
    };

    const closeColorPicker = () => {
        setShowColorPicker(false);
    };

    const handleColorChange = (color) => {
        setColorThem(color.hex);
    };
    const handleLogoChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setLogo(file);
            setLogoPreview(URL.createObjectURL(file));
        }
    };
    const handleImageChange = (event) => {
        const selectedUserImage = event.target.files[0];
        if (selectedUserImage) {
            setUserImage(selectedUserImage);
            setUserImagePreview(URL.createObjectURL(selectedUserImage));
        }
    };
    const handleSave = async () => {

        const formData = new FormData();
        formData.append('name', name);
        formData.append('email', email);
        formData.append('phone', phone);
        formData.append('color_them', colorThem);
        formData.append("_method", "PUT");
        formData.append('userName', userName);
        formData.append('userEmail', userEmail);
        formData.append('userPhone', userPhone);

        if (logo) {
            formData.append('logo', logo);
        }
        if (userImage) {
            formData.append('userImage', userImage);
        }
        try {
            setIsUpdating(true);
            const response = await axios.post(
                process.env.REACT_APP_API_URL + '/company/' + currentCompanyData.id,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data', // Set the correct content type
                    },
                }
            );

            if (response.data.status) {
                Swal.fire('Done!', "Details has been updated you need to re-login again for updates", 'success').then(() => {

                });
            } else {
                Swal.fire('Oops!', response.data.message, 'error');
            }
        } catch (error) {
            console.error('Error updating company:', error);
            // Handle error scenarios
        } finally {
            setIsUpdating(false);
        }
    };

    const validatePassword = () => {
        const errors = {};

        if (!currentPassword) {
            errors.currentPassword = 'Current password is required';
        }

        if (!newPassword) {
            errors.newPassword = 'Password is required';
        } else if (newPassword.length < 8) {
            errors.newPassword = 'Password must be at least 8 characters long';
        }

        if (!confirmPassword) {
            errors.confirmPassword = 'Confirm password is required';
        } else if (newPassword !== confirmPassword) {
            errors.confirmPassword = 'Passwords do not match';
        }

        return errors;
    };
    const handleChangePassword = async () => {
        const errors = validatePassword();

        if (Object.keys(errors).length === 0) {
            try {
                setIsUpdating(true);
                const data = {
                    email: currentUserData.email,
                    password: currentPassword,
                    newPassword: confirmPassword,
                    userId: currentUserData.id
                }
                const response = await axios.post(process.env.REACT_APP_API_URL + '/update-password', data,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            'Content-Type': 'multipart/form-data', // Set the correct content type
                        },
                    }
                );

                if (response.data.status) {
                    Swal.fire('Done!', "Details has been updated you need to re-login again for updates", 'success').then(() => {

                    });
                } else {
                    Swal.fire('Oops!', response.data.message, 'error');
                }
                setPasswordErrors('');
            } catch (error) {
                // Handle API request error
                console.error('Error changing password:', error);
            }
            finally {
                setIsUpdating(false);
            }
        } else {
            setPasswordErrors(errors);
        }
    };

    return (
        <>
            <Container fluid>
                <Tab.Container defaultActiveKey="personal-information">
                    <Row>
                        <Col lg="12">
                            <Card>
                                <Card.Body className="p-0">
                                    <div className="mm-edit-list usr-edit">
                                        <Nav variant="pills" className="mm-edit-profile d-flex">
                                            <li className="col-md-3 p-0">
                                                <Nav.Link eventKey="personal-information">Personal Information</Nav.Link>
                                            </li>
                                            <li className="col-md-3 p-0">
                                                <Nav.Link eventKey="chang-pwd">Change Password</Nav.Link>
                                            </li>

                                        </Nav>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg="12">
                            <div className="mm-edit-list-data">
                                <Tab.Content>
                                    <Tab.Pane eventKey="personal-information" role="tabpanel">
                                        <Card>
                                            <Card.Header>
                                                <Card.Header.Title>
                                                    <h4 className="card-title">Company Information</h4>
                                                </Card.Header.Title>
                                            </Card.Header>
                                            <Card.Body>
                                                <Form>
                                                    <Row className="form-group  align-items-center">
                                                        <Col md="12">
                                                            <div className="profile-img-edit">
                                                                <div className="crm-profile-img-edit">
                                                                    <img className="crm-profile-pic rounded-circle avatar-100" src={logoPreview ? logoPreview : user1} alt="profile-pic" />
                                                                    <div className="crm-p-image bg-primary">
                                                                        <label htmlFor="imageUpload" className="upload-label text-white" style={{ cursor: 'pointer' }}>
                                                                            <i className="las la-pen upload-button upload-label"></i>
                                                                        </label>
                                                                        <input
                                                                            className="file-upload"
                                                                            type="file"
                                                                            accept="image/*"
                                                                            id="imageUpload"
                                                                            onChange={handleLogoChange}
                                                                            style={{ display: 'none' }} // Hide the input element
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row className=" align-items-center">
                                                        <div className="col-md-6 mb-3">
                                                            <Form.Label htmlFor="Text1" className="font-weight-bold text-muted text-uppercase">Name</Form.Label>
                                                            <Form.Control type="text" id="Text1" placeholder="Name" name="name"
                                                                value={name}
                                                                onChange={(e) => setName(e.target.value)} />
                                                        </div>

                                                        <div className="col-md-6 mb-3">
                                                            <Form.Label htmlFor="Text4" className="font-weight-bold text-muted text-uppercase">Email</Form.Label>
                                                            <Form.Control type="text" id="Text4" placeholder="Enter Email" name="email"
                                                                value={email}
                                                                onChange={(e) => setEmail(e.target.value)}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 mb-3">
                                                            <Form.Label htmlFor="Text5" className="font-weight-bold text-muted text-uppercase">Phone</Form.Label>
                                                            <Form.Control type="text" id="Text5" placeholder="Enter Phone" name="phone"
                                                                value={phone}
                                                                onChange={(e) => setPhone(e.target.value)} />
                                                        </div>
                                                        <div className="col-md-6 mb-3">
                                                            <Form.Label htmlFor="inputState" className="font-weight-bold text-muted text-uppercase">Theme Color</Form.Label>
                                                            <div className="input-group my-colorpicker2">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    value={colorThem}

                                                                    onChange={(e) => setColorThem(e.target.value)}
                                                                />
                                                                <div className="input-group-append">
                                                                    <span className="input-group-text" style={{ cursor: 'pointer' }} onClick={toggleColorPicker}>
                                                                        <i className="fas fa-square" style={{ color: colorThem }} />
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            {showColorPicker && (
                                                                <div className="color-picker-popover" onClick={closeColorPicker}>
                                                                    <ChromePicker color={colorThem} onChange={handleColorChange} />
                                                                </div>
                                                            )}
                                                        </div>
                                                    </Row>

                                                </Form>
                                            </Card.Body>
                                        </Card>
                                        <Card>
                                            <Card.Header>
                                                <Card.Header.Title>
                                                    <h4 className="card-title">User Information</h4>
                                                </Card.Header.Title>
                                            </Card.Header>
                                            <Card.Body>
                                                <Row className="form-group  align-items-center">
                                                    <Col md="12">
                                                        <div className="profile-img-edit">
                                                            <div className="crm-profile-img-edit">
                                                                <img className="crm-profile-pic rounded-circle avatar-100" src={userImagePreview ? userImagePreview : user1} alt="profile-pic" />
                                                                <div className="crm-p-image bg-primary">

                                                                    <label htmlFor="userImageUpload" className="upload-label text-white" style={{ cursor: 'pointer' }}>
                                                                        <i className="las la-pen upload-button"></i>
                                                                    </label>
                                                                    <input
                                                                        type="file"
                                                                        accept="image/*"
                                                                        id="userImageUpload"
                                                                        onChange={handleImageChange}
                                                                        style={{ display: 'none' }} // Hide the input element
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row className=" align-items-center">
                                                    <Col md="12">
                                                        <Form className="row g-3 date-icon-set-modal">
                                                            <div className="col-md-6 mb-3">
                                                                <Form.Label htmlFor="Text1" className="font-weight-bold text-muted text-uppercase">Name</Form.Label>
                                                                <Form.Control type="text" id="Text1" placeholder="Name" name="name"
                                                                    value={userName}
                                                                    onChange={(e) => setUserName(e.target.value)} />
                                                            </div>

                                                            <div className="col-md-6 mb-3">
                                                                <Form.Label htmlFor="Text4" className="font-weight-bold text-muted text-uppercase">Email</Form.Label>
                                                                <Form.Control type="text" id="Text4" placeholder="Enter Email" name="email"
                                                                    value={userEmail}
                                                                    onChange={(e) => setUserEmail(e.target.value)}
                                                                />
                                                            </div>
                                                            <div className="col-md-6 mb-3">
                                                                <Form.Label htmlFor="Text5" className="font-weight-bold text-muted text-uppercase">Phone</Form.Label>
                                                                <Form.Control type="text" id="Text5" placeholder="Enter Phone" name="phone"
                                                                    value={userPhone}
                                                                    onChange={(e) => setUserPhone(e.target.value)} />
                                                            </div>

                                                        </Form>

                                                    </Col>
                                                    <Button type="submit" variant="primary"
                                                        onClick={handleSave}
                                                        disabled={isUpdating}
                                                    >
                                                        {isUpdating ? 'Updating...' : 'Update Details'}
                                                    </Button>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="chang-pwd" role="tabpanel">
                                        <Card>
                                            <Card.Header>
                                                <Card.Header.Title>
                                                    <h4 className="card-title">Change Password</h4>
                                                </Card.Header.Title>
                                            </Card.Header>
                                            <Card.Body>
                                                <Form>
                                                    <Form.Group controlId="cpass">
                                                        <Form.Label>Current Password:</Form.Label>
                                                        <Form.Control
                                                            type="password"
                                                            value={currentPassword}
                                                            onChange={(e) => setCurrentPassword(e.target.value)}
                                                            isInvalid={passwordErrors.currentPassword}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {passwordErrors.currentPassword}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                    <Form.Group controlId="npass">
                                                        <Form.Label>New Password:</Form.Label>
                                                        <Form.Control
                                                            type="password"
                                                            value={newPassword}
                                                            onChange={(e) => setNewPassword(e.target.value)}
                                                            isInvalid={passwordErrors.newPassword}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {passwordErrors.newPassword}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                    <Form.Group controlId="vpass">
                                                        <Form.Label>Verify Password:</Form.Label>
                                                        <Form.Control
                                                            type="password"
                                                            value={confirmPassword}
                                                            onChange={(e) => setConfirmPassword(e.target.value)}
                                                            isInvalid={passwordErrors.confirmPassword}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {passwordErrors.confirmPassword}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                    <Button
                                                        type="button"
                                                        variant="outline-primary mr-2"
                                                        onClick={() => {
                                                            setCurrentPassword('');
                                                            setNewPassword('');
                                                            setConfirmPassword('');
                                                            setPasswordErrors({});
                                                        }}
                                                    >
                                                        Cancel
                                                    </Button>
                                                    <Button
                                                        type="button"
                                                        variant="primary"
                                                        onClick={handleChangePassword}
                                                    >
                                                        Submit
                                                    </Button>
                                                </Form>
                                            </Card.Body>
                                        </Card>
                                    </Tab.Pane>


                                </Tab.Content>
                            </div>
                        </Col>
                    </Row>
                </Tab.Container>
            </Container>
        </>
    )
}

export default UserProfileEdit