import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import Card from '../../../components/Card'
import { Link, useParams } from 'react-router-dom'
import axios from 'axios'; // Import Axios
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
import './drop-image.css';


const Productedit = ({ user }) => {
    const history = useHistory();
    const { productId } = useParams();
    const [formErrors, setFormErrors] = useState({});
    const [currencies, setCurrencies] = useState([]);
    const [categories, setCategories] = useState([]);
    const [formData, setFormData] = useState({
        productName: '',
        categoryId: '',
        inventoryStatus: '',
        currency: '',
        productCode: '',
        quantity: '',
        reorderLevel: '',
        inventoryStatus: '',
        currency: '',
        price: '',
        description: ''
    });
    const [imageFile, setImageFile] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [barcode, setBarcode] = useState('');
    const [barcodePreview, setBarcodePreview] = useState({});
    const [imagePreviewUrl, setImagePreviewUrl] = useState('');
    const token = user.user.user.token

    useEffect(() => {
        fetchProductData();
        fetchCategories();
        fetchCurrencies();
    }, []);
    const fetchProductData = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/companies/${user.user.user.companyDetails.id}/products/${productId}/edit`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const productData = response.data.productData; // Replace with your API response structure
            productData._method = "PUT";
            setFormData(productData);
            setImageFile(productData.image);
            setImagePreviewUrl(process.env.REACT_APP_BACKEND_URL + productData.image);
            setBarcodePreview(process.env.REACT_APP_BACKEND_URL + productData.barcodePreview);
            setBarcode(productData.barcode);
        } catch (error) {
            console.error('Error fetching product:', error);
            // Handle error scenarios here
        }
    };
    const fetchCurrencies = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_URL + '/currencies', {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
            setCurrencies(response.data.currencies);
        } catch (error) {
            console.error('Error fetching currencies:', error);
        }
    };
    const fetchCategories = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_URL + '/companies/' + user.user.user.companyDetails.id + '/categories', {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
            setCategories(response.data.categories);

        } catch (error) {
            console.error('Error fetching categories:', error);
            // Handle error scenarios here
        }
    };
    const handleImageDrop = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];

        // Check if the dropped file is an image
        if (file.type.startsWith('image/')) {
            setImageFile(file);
            setFormData((prevFormData) => ({
                ...prevFormData,
                image: file,
            }));
            setImagePreviewUrl(URL.createObjectURL(file));
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Invalid File Type',
                text: 'Please drop an image file (e.g., JPEG, PNG)',
            });
        }
    };
    const handleFileChange = (event) => {
        const file = event.target.files[0];

        // Check if the selected file is an image
        if (file && file.type.startsWith('image/')) {
            setImageFile(file);
            setFormData((prevFormData) => ({
                ...prevFormData,
                image: file,
            }));
            setImagePreviewUrl(URL.createObjectURL(file));
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Invalid File Type',
                text: 'Please select an image file (e.g., JPEG, PNG)',
            });
        }
    };

    const handleImageClick = () => {
        const fileInput = document.getElementById('fileInput');
        if (fileInput) {
            fileInput.click();
        }
    };
    const removeImage = (event) => {
        event.stopPropagation();
        setImageFile(null);
        setFormData((prevFormData) => {
            const updatedFormData = { ...prevFormData };
            delete updatedFormData.image;
            return updatedFormData;
        });
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };
    const validateForm = () => {
        const errors = {};

        if (!formData.productName) {
            errors.productName = 'Product name is required';
        }

        if (!formData.categoryId) {
            errors.categoryId = 'Category is required';
        }

        if (!formData.inventoryStatus) {
            errors.inventoryStatus = 'Inventory status is required';
        }

        if (!formData.currency) {
            errors.currency = 'Currency is required';
        }

        if (!formData.productCode) {
            errors.productCode = 'Product code is required';
        }

        if (!formData.quantity || isNaN(formData.quantity)) {
            errors.quantity = 'Quantity is required and must be a number';
        }


        if (!formData.inventoryStatus) {
            errors.inventoryStatus = 'Inventory status is required';
        }

        if (!formData.currency) {
            errors.currency = 'Currency is required';
        }

        if (!formData.price || isNaN(formData.price)) {
            errors.price = 'Price is required and must be a number';
        }
        if (!formData.description) {
            errors.description = 'Product description is required';
        }
        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };
    const handleFormSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) {
            return;
        }
        setIsSubmitting(true); // Set loading state
        try {
            const updatedFormData = { ...formData };
            if (imageFile == 1) {
                delete updatedFormData.image;
            }
            const response = await axios.post(process.env.REACT_APP_API_URL + '/companies/' + user.user.user.companyDetails.id + '/products/' + productId, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            }); // Replace with your API endpoint
            if (response.data.status) {
                Swal.fire('Done!', response.data.message, 'success').then(() => {
                    history.push('/products');
                });
            } else {
                Swal.fire('Ooopsss!', response.data.message, 'error');
            }
            // You can perform any necessary actions after successful submission
        } catch (error) {
            console.log(error);
            Swal.fire('Ooopsss!', error.message, 'error');
            // Handle error scenarios here
        } finally {
            setIsSubmitting(false); // Reset loading state
        }
    };
    return (
        <>
            <Container fluid>
                <Row>
                    <Col lg="12">
                        <div className="d-flex flex-wrap align-items-center justify-content-between">
                            <div className="d-flex align-items-center justify-content-between">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb p-0 mb-0">
                                        <li className="breadcrumb-item"><Link to="/products">Products</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">Edit Product</li>
                                    </ol>
                                </nav>
                            </div>
                            <Link to="/products" className="btn btn-primary btn-sm d-flex align-items-center justify-content-between ml-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
                                </svg>
                                <span className="ml-2">Back</span>
                            </Link>
                        </div>
                    </Col>
                    <Col lg="12" className="mb-3 d-flex justify-content-between">
                        <h4 className="font-weight-bold d-flex align-items-center">Edit Product</h4>
                    </Col>
                    <Col lg="12">
                        <Card>
                            <Card.Body>
                                <h5 className="font-weight-bold mb-3">Basic Information</h5>
                                <Form className="row g-3">
                                    <div className="col-md-6 mb-3">
                                        <Form.Label htmlFor="Text1" className="form-label font-weight-bold text-muted text-uppercase">Product Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            className="form-control"
                                            id="Text1"
                                            name="productName"
                                            placeholder="Enter Product Name"
                                            value={formData.productName}
                                            onChange={handleInputChange}
                                            isInvalid={!!formErrors.productName} // Add this line
                                        />
                                        <Form.Control.Feedback type="invalid"> {/* Add this block */}
                                            {formErrors.productName}
                                        </Form.Control.Feedback>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <Form.Label htmlFor="inputState" className="form-label font-weight-bold text-muted text-uppercase">Category</Form.Label>
                                        <select
                                            id="inputState"
                                            className={`form-select form-control choicesjs ${formErrors.categoryId ? 'is-invalid' : ''}`}
                                            name="categoryId" // Add a name to the select
                                            value={formData.categoryId} // Bind the value to the state
                                            onChange={handleInputChange} // Connect the select to the handler

                                        >
                                            <option value="">Select Category</option>
                                            {categories.map((category) => (
                                                <option key={category.id} value={category.id}>
                                                    {category.name}
                                                </option>
                                            ))}
                                        </select>
                                        <Form.Control.Feedback type="invalid"> {/* Add this block */}
                                            {formErrors.categoryId}
                                        </Form.Control.Feedback>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <Form.Label htmlFor="Text2" className="form-label font-weight-bold text-muted text-uppercase">Product Code</Form.Label>
                                        <Form.Control
                                            type="text"
                                            className="form-control"
                                            id="Text2"
                                            name="productCode" // Add a name to the input
                                            placeholder="Enter Product Code"
                                            value={formData.productCode} // Bind the value to the state
                                            onChange={handleInputChange} // Connect the input to the handler
                                            isInvalid={!!formErrors.productCode}
                                        />
                                        <Form.Control.Feedback type="invalid"> {/* Add this block */}
                                            {formErrors.productCode}
                                        </Form.Control.Feedback>
                                    </div>

                                    <div className="col-md-6 mb-3">
                                        <Form.Label htmlFor="Text5" className="form-label font-weight-bold text-muted text-uppercase">Quantity</Form.Label>
                                        <Form.Control
                                            type="text"
                                            className="form-control"
                                            id="Text5"
                                            name="quantity" // Add a name to the input
                                            value={formData.quantity} // Bind the value to the state
                                            onChange={handleInputChange} // Connect the input to the handler
                                            placeholder="Enter Quantity"
                                            isInvalid={!!formErrors.quantity}
                                        />
                                        <Form.Control.Feedback type="invalid"> {/* Add this block */}
                                            {formErrors.quantity}
                                        </Form.Control.Feedback>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <Form.Label htmlFor="Text5" className="form-label font-weight-bold text-muted text-uppercase">Re-Order Level</Form.Label>
                                        <Form.Control
                                            type="text"
                                            className="form-control"
                                            id="Text5"
                                            name="reorderLevel" // Add a name to the input
                                            value={formData.reorderLevel} // Bind the value to the state
                                            onChange={handleInputChange} // Connect the input to the handler
                                            placeholder="Enter Re-Order Level"
                                            isInvalid={!!formErrors.reorderLevel}
                                        />
                                        <Form.Control.Feedback type="invalid"> {/* Add this block */}
                                            {formErrors.reorderLevel}
                                        </Form.Control.Feedback>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <Form.Label htmlFor="inputState" className="form-label font-weight-bold text-muted text-uppercase">Inventory Status</Form.Label>
                                        <select
                                            id="inputState"
                                            className={`form-select form-control choicesjs ${formErrors.inventoryStatus ? 'is-invalid' : ''}`}
                                            name="inventoryStatus" // Add a name to the select
                                            value={formData.inventoryStatus} // Bind the value to the state
                                            onChange={handleInputChange} // Connect the select to the handler
                                        >
                                            <option value="">Select Inventory Status</option>
                                            <option value="in_stock">In Stock</option>
                                            <option value="out_of_stock">Out of Stock</option>
                                        </select>
                                        <Form.Control.Feedback type="invalid"> {/* Add this block */}
                                            {formErrors.inventoryStatus}
                                        </Form.Control.Feedback>
                                    </div>

                                    <div className="col-md-6 mb-3">
                                        <Form.Label htmlFor="inputState" className="form-label font-weight-bold text-muted text-uppercase">Currency</Form.Label>
                                        <select
                                            id="inputState"
                                            className={`form-select form-control choicesjs ${formErrors.currency ? 'is-invalid' : ''}`}
                                            name="currency" // Add a name to the select
                                            value={formData.currency} // Bind the value to the state
                                            onChange={handleInputChange} // Connect the select to the handler
                                        >
                                            <option value="">Select Currency</option>
                                            {currencies.map((currency) => (
                                                <option key={currency.id} value={currency.id}>
                                                    {currency.name}
                                                </option>
                                            ))}
                                        </select>
                                        <Form.Control.Feedback type="invalid"> {/* Add this block */}
                                            {formErrors.currency}
                                        </Form.Control.Feedback>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <Form.Label htmlFor="Text6" className="form-label font-weight-bold text-muted text-uppercase">Price</Form.Label>
                                        <Form.Control
                                            type="text"
                                            className="form-control"
                                            id="Text6"
                                            placeholder="Enter Price"
                                            name="price" // Add a name to the input
                                            value={formData.price} // Bind the value to the state
                                            onChange={handleInputChange} // Connect the input to the handler
                                            isInvalid={!!formErrors.price}
                                        />
                                        <Form.Control.Feedback type="invalid"> {/* Add this block */}
                                            {formErrors.price}
                                        </Form.Control.Feedback>
                                    </div>

                                    <div className="barcode-line col-md-6 mb-3">
                                        <Form.Label htmlFor="Text9" className="form-label font-weight-bold text-muted text-uppercase">Barcode</Form.Label>

                                        <div className="barcode">
                                            <img src={barcodePreview}></img>
                                        </div>
                                        <div className=" barcode-value">{barcode}</div>
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <Form.Label htmlFor="Text9" className="form-label font-weight-bold text-muted text-uppercase">Description</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            id="Text9"
                                            rows="2"
                                            placeholder="Enter Description"
                                            name="description" // Add a name to the textarea
                                            value={formData.description} // Bind the value to the state
                                            onChange={handleInputChange} // Connect the textarea to the handler
                                            isInvalid={!!formErrors.description}
                                        />
                                        <Form.Control.Feedback type="invalid"> {/* Add this block */}
                                            {formErrors.description}
                                        </Form.Control.Feedback>
                                    </div>
                                </Form>
                            </Card.Body>
                        </Card>
                        <Card>
                            <Card.Body>
                                <h5 className="font-weight-bold mb-3">Product Image</h5>
                                <Card.Body className="bg-secondary rounded">
                                    <div
                                        className="d-flex flex-column align-items-center mt-5 upload-area"
                                        onDragOver={(e) => e.preventDefault()}
                                        onDrop={handleImageDrop}
                                        onClick={handleImageClick}
                                    >
                                        {imageFile ? (
                                            <div className="uploaded-image-container">
                                                <img src={imagePreviewUrl} alt="Uploaded" className="uploaded-image" />
                                                <button className="remove-image-button" onClick={removeImage}>
                                                    <i className="fas fa-times"></i>
                                                </button>
                                            </div>
                                        ) : (
                                            <div className="uploaded-image-container">
                                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" height="120px" x="0px" y="0px" viewBox="0 0 419.2 419.2" style={{ enableBackground: "new 0 0 419.2 419.2" }} stroke="currentColor">
                                                    <g>
                                                        <g>
                                                            <g>
                                                                <circle cx="158" cy="144.4" r="28.8" />
                                                                <path d="M394.4,250.4c-13.6-12.8-30.8-21.2-49.6-23.6V80.4c0-15.6-6.4-29.6-16.4-40C318,30,304,24,288.4,24h-232     c-15.6,0-29.6,6.4-40,16.4C6,50.8,0,64.8,0,80.4v184.4V282v37.2c0,15.6,6.4,29.6,16.4,40c10.4,10.4,24.4,16.4,40,16.4h224.4     c14.8,12,33.2,19.6,53.6,19.6c23.6,0,44.8-9.6,60-24.8c15.2-15.2,24.8-36.4,24.8-60C419.2,286.8,409.6,265.6,394.4,250.4z      M21.2,80.4c0-9.6,4-18.4,10.4-24.4c6.4-6.4,15.2-10.4,24.8-10.4h232c9.6,0,18.4,4,24.8,10.4c6.4,6.4,10.4,15.2,10.4,24.8v124.8     l-59.2-59.2c-4-4-10.8-4.4-15.2,0L160,236l-60.4-60.8c-4-4-10.8-4.4-15.2,0l-63.2,64V80.4z M56,355.2v-0.8     c-9.6,0-18.4-4-24.8-10.4c-6-6.4-10-15.2-10-24.8V282v-12.4L92,198.4l60.4,60.4c4,4,10.8,4,15.2,0l89.2-89.6l58.4,58.8     c-1.2,0.4-2.4,0.8-3.6,1.2c-1.6,0.4-3.2,0.8-5.2,1.6c-1.6,0.4-3.2,1.2-4.8,1.6c-1.2,0.4-2,0.8-3.2,1.6c-1.6,0.8-2.8,1.2-4,2     c-2,1.2-4,2.4-6,3.6c-1.2,0.8-2,1.2-3.2,2c-0.8,0.4-1.2,0.8-2,1.2c-3.6,2.4-6.8,5.2-9.6,8.4c-15.2,15.2-24.8,36.4-24.8,60     c0,6,0.8,11.6,2,17.6c0.4,1.6,0.8,2.8,1.2,4.4c1.2,4,2.4,8,4,12v0.4c1.6,3.2,3.2,6.8,5.2,9.6H56z M378.8,355.2     c-11.6,11.6-27.2,18.4-44.8,18.4c-16.8,0-32.4-6.8-43.6-17.6c-1.6-1.6-3.2-3.6-4.8-5.2c-1.2-1.2-2.4-2.8-3.6-4     c-1.6-2-2.8-4.4-4-6.8c-0.8-1.6-1.6-2.8-2.4-4.4c-0.8-2-1.6-4.4-2-6.8c-0.4-1.6-1.2-3.6-1.6-5.2c-0.8-4-1.2-8.4-1.2-12.8     c0-17.6,7.2-33.2,18.4-44.8c11.2-11.6,27.2-18.4,44.8-18.4s33.2,7.2,44.8,18.4c11.6,11.6,18.4,27.2,18.4,44.8     C397.2,328,390,343.6,378.8,355.2z" />
                                                                <path d="M341.6,267.6c-0.8-0.8-2-1.6-3.6-2.4c-1.2-0.4-2.4-0.8-3.6-0.8c-0.4,0-0.4,0-0.4,0c-0.4,0-0.4,0-0.4,0     c-1.2,0-2.4,0.4-3.6,0.8c-1.2,0.4-2.4,1.2-3.6,2.4l-24.8,24.8c-4,4-4,10.8,0,15.2c4,4,10.8,4,15.2,0l6.4-6.4v44     c0,6,4.8,10.8,10.8,10.8s10.8-4.8,10.8-10.8v-44l6.4,6.4c4,4,10.8,4,15.2,0c4-4,4-10.8,0-15.2L341.6,267.6z" />
                                                            </g>
                                                        </g>
                                                    </g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g>
                                                </svg>
                                            </div>
                                        )}
                                        <p className="upload-text">Click or drop files here to upload</p>
                                    </div>
                                    <input
                                        type="file"
                                        id="fileInput"
                                        className="d-none"
                                        accept="image/*"
                                        onChange={handleFileChange}
                                    />

                                </Card.Body>
                                <div className="d-flex justify-content-end mt-3">
                                    <Button variant="btn btn-primary" onClick={handleFormSubmit} disabled={isSubmitting}>
                                        {isSubmitting ? "Saving..." : "Save Product"}
                                    </Button>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container >
        </>
    )
}
export default Productedit;