import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, ListGroup } from 'react-bootstrap';
import Card from '../../../components/Card';
import { Link, useParams, useHistory } from 'react-router-dom';
import axios from 'axios';
import Select from 'react-select';
import Swal from 'sweetalert2';
import './custome-select.css'

const Orderedit = ({ user }) => {
    const { orderId } = useParams(); // Get the category ID from the URL params
    let history = useHistory();
    const [customers, setCustomers] = useState([]);
    const [productsList, setProductsList] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [currentDate, setCurrentDate] = useState('');
    const [selectedProduct, setSelectedProduct] = useState('');
    const [selectedQuantity, setSelectedQuantity] = useState(1);
    const [orderItems, setOrderItems] = useState([]);
    const [totalPrice, setTotalPrice] = useState(0);
    const [selectedCurrency, setSelectedCurrency] = useState('');
    const [totalAmount, setTotalAmount] = useState(0);
    const [discountAmount, setDiscountAmount] = useState(0);
    const [dueDate, setDueDate] = useState('');
    const [invoiceStatus, setInvoiceStatus] = useState('');
    const [totalPayable, setTotalPayable] = useState(0);
    const [currencyOptions, setCurrencyOptions] = useState([]);
    const [comment, setComment] = useState('');
    const [invoiceId, setInvoiceId] = useState('');

    const token = user.user.user.token;


    useEffect(() => {
        const currentDate = new Date();
        const formattedDate = `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}-${String(currentDate.getDate()).padStart(2, '0')}`;
        setCurrentDate(formattedDate);
        fetchCustomers();
        fetchProducts();
        fetchCurrencyOptions();
        fetchOrderDetails(orderId);
    }, []);

    const fetchOrderDetails = (orderId) => {
        // Fetch the order details from your API and populate the state variables accordingly.
        // You might want to make an API call to get the order details and update the state.
        // Example:
        axios.get(process.env.REACT_APP_API_URL + '/companies/' + user.user.user.companyDetails.id + '/orders/' + orderId, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => {
                const fetchedOrderData = response.data;
                if (fetchedOrderData.status) {
                    setPhone(fetchedOrderData.orderData.customer.phone);
                    setEmail(fetchedOrderData.orderData.customer.email);
                    setAddress(fetchedOrderData.orderData.customer.address);
                    setComment(fetchedOrderData.orderData.comments !== null ? fetchedOrderData.orderData.comments : "");
                    setCurrentDate(fetchedOrderData.orderData.date)
                    setSelectedCustomer({ value: fetchedOrderData.orderData.customer.id, label: fetchedOrderData.orderData.customer.name });
                    setOrderItems(fetchedOrderData.orderData.order_items);
                    setSelectedCurrency(fetchedOrderData.orderData.currency_id);
                    setTotalPrice(parseFloat(fetchedOrderData.orderData.invoice.total_amount));
                    setDiscountAmount(parseFloat(fetchedOrderData.orderData.invoice.discount_amount));
                    setTotalPayable(parseFloat(fetchedOrderData.orderData.invoice.paid));
                    setInvoiceStatus(fetchedOrderData.orderData.invoice.invoice_status);
                    setDueDate(fetchedOrderData.orderData.invoice.due_date);
                    setInvoiceId(fetchedOrderData.orderData.invoice.id);
                    setTotalAmount(parseFloat(fetchedOrderData.orderData.invoice.total_amount));
                }
            })
            .catch((error) => {
                console.error('Error fetching order details:', error);
            });
    }

    const fetchCustomers = () => {

        axios.get(process.env.REACT_APP_API_URL + '/companies/' + user.user.user.companyDetails.id + '/customers', {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        })
            .then((response) => {
                const fetchedCustomers = response.data;
                if (fetchedCustomers.status) {
                    setCustomers(fetchedCustomers.data);
                }
            })
            .catch((error) => {
                console.error('Error fetching products:', error);
            });
    }
    const fetchProducts = () => {

        axios.get(process.env.REACT_APP_API_URL + '/companies/' + user.user.user.companyDetails.id + '/products', {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        })
            .then((response) => {
                const fetchedProducts = response.data;
                if (fetchedProducts.status) {
                    const inStockProducts = fetchedProducts.data.filter(product => product.inventory_status === "in_stock");
                    setProductsList(inStockProducts);
                }
            })
            .catch((error) => {
                console.error('Error fetching products:', error);
            });
    }
    const fetchCurrencyOptions = () => {
        axios.get(process.env.REACT_APP_API_URL + '/currencies', {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        })
            .then((response) => {
                const fetchedCurrencies = response.data;
                setCurrencyOptions(fetchedCurrencies.currencies);
            })
            .catch((error) => {
                console.error('Error fetching currencies:', error);
            });
    };

    const handleCustomerSelect = (selectedOption) => {
        const selectedCustomerId = selectedOption.value;
        const selectedCustomer = customers.find(customer => customer.id === selectedCustomerId);

        setSelectedCustomer(selectedOption);
        if (selectedCustomer) {
            setPhone(selectedCustomer.phone);
            setEmail(selectedCustomer.email);
            setAddress(selectedCustomer.address);
        }
    };

    const addItemToOrder = () => {
        const product = productsList.find(p => p.id === selectedProduct.value);
        if (product) {
            product.images = product.product_image;
            const newItem = {
                product: product,
                price: product.price,
                quantity: selectedQuantity
            };

            setOrderItems([...orderItems, newItem]);
            setTotalAmount(totalPrice + product.price * selectedQuantity);
            setTotalPrice(totalPrice + product.price * selectedQuantity);
            setTotalPayable(totalPrice + product.price * selectedQuantity);

        }
    };

    // Function to handle changing quantity
    const handleQuantityChange = (index, newQuantity) => {
        if (newQuantity > 0) {
            const updatedItems = [...orderItems];
            updatedItems[index].quantity = newQuantity;

            setOrderItems(updatedItems);
            updateTotalPrice();
        }
    };

    // Function to update total price
    const updateTotalPrice = () => {
        const newTotal = orderItems.reduce(
            (total, item) => total + item.product.price * item.quantity,
            0
        );
        setTotalPrice(newTotal);
        setTotalAmount(newTotal);
    };
    const removeItem = (index) => {
        const updatedItems = [...orderItems];
        const removedItem = updatedItems.splice(index, 1)[0];
        setOrderItems(updatedItems);
        setTotalPrice(totalPrice - removedItem.product.price * removedItem.quantity);
    };

    const validateForm = () => {
        if (!selectedCustomer) {
            Swal.fire({
                icon: 'error',
                title: 'Validation Error',
                text: 'Please select a customer.',
            });
            return false;
        }
        if (!phone || !email || !address) {
            Swal.fire({
                icon: 'error',
                title: 'Validation Error',
                text: 'Please fill in all customer details (phone, email, address).',
            });
            return false;
        }
        if (orderItems.length === 0) {
            Swal.fire({
                icon: 'error',
                title: 'Validation Error',
                text: 'Please add at least one item to the order.',
            });
            return false;
        }
        if (!dueDate) {
            Swal.fire({
                icon: 'error',
                title: 'Validation Error',
                text: 'Please select a due date.',
            });
            return false;
        }
        if (totalAmount <= 0) {
            Swal.fire({
                icon: 'error',
                title: 'Validation Error',
                text: 'Total amount must be greater than 0.',
            });
            return false;
        }
        if (selectedCurrency === "") {
            Swal.fire({
                icon: 'error',
                title: 'Validation Error',
                text: 'Please select invoice currency',
            });
            return false;
        }
        if (invoiceStatus === "") {
            Swal.fire({
                icon: 'error',
                title: 'Validation Error',
                text: 'Please select invoice status',
            });
            return false;
        }
        return true;
    };
    const showInvoiceActionsDialog = (url, invoiceId) => {
        Swal.fire({
            title: 'Invoice Actions',
            html:
                `<div>
                <div class="mb-3">
                    <label for="copyLink" class="form-label">Copy Invoice Link</label>
                    <input type="text" class="form-control" id="copyLink" value=${url} readonly>
                    
                    <button class="btn btn-primary mt-2" id="copyButton">Copy</button>
                </div>
                <div class="mb-3">
                <span class="text-success ml-2" id="linkMessage"></span>
                </div>
                <div class="mb-3">
                    <label for="emailInput" class="form-label">Send Email to Customer</label>
                    <input type="email" class="form-control" id="emailInput" value=${email}>
                    
                    <button class="btn btn-primary mt-2" id="sendEmailButton">Send Email</button>
                </div>
                <div class="mb-3">
                <span class="text-success ml-2" id="emailMessageSuccess"></span>
                    <span class="text-danger ml-2" id="emailMessageError"></span>
                </div>
            </div>`,
            showCancelButton: true,
            showConfirmButton: false,
            cancelButtonText: 'Close',
        });

        // Copy link button functionality
        const copyButton = document.getElementById('copyButton');
        copyButton.addEventListener('click', () => {
            const copyLinkInput = document.getElementById('copyLink');
            copyLinkInput.select();
            document.execCommand('copy');
            document.getElementById('linkMessage').innerHTML = "Link Copied!";

        });

        // Send email button functionality
        const sendEmailButton = document.getElementById('sendEmailButton');
        sendEmailButton.addEventListener('click', () => {
            const emailInput = document.getElementById('emailInput');
            if (emailInput.value.trim() === '') {
                document.getElementById('emailMessageError').innerHTML = "Please enter a valid email address!";
            } else {
                sendEmailButton.innerHTML = "Sending....";
                sendEmailButton.disabled = true;
                const invoiceData = {
                    invoiceId: invoiceId,
                    toEmail: emailInput.value,
                }
                axios.post(process.env.REACT_APP_API_URL + '/send-invoice', invoiceData, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                })
                    .then(response => {
                        if (response.data.status) {
                            sendEmailButton.innerHTML = "Send Email";
                            sendEmailButton.disabled = false;
                            document.getElementById('emailMessageSuccess').innerHTML = "The invoice has been sent to the customer\'s email.";
                        } else {
                            sendEmailButton.innerHTML = "Send Email";
                            sendEmailButton.disabled = false;
                            document.getElementById('emailMessageError').innerHTML = 'Sending invoice failed';
                        }
                    })
                    .catch(error => {
                        sendEmailButton.innerHTML = "Send Email";
                        sendEmailButton.disabled = false;
                        document.getElementById('emailMessageError').innerHTML = 'Sending invoice failed';
                    });

            }
        });
    };
    const createOrder = () => {
        if (!validateForm()) {
            return;
        }

        // Prepare the order data
        const orderData = {
            customer_id: selectedCustomer.value,
            date: currentDate,
            comment: comment, // Set the comment value here
            due_date: dueDate,
            invoice_status: invoiceStatus,
            invoice_id: invoiceId,
            total_amount: totalAmount,
            discount_amount: discountAmount,
            currency_id: selectedCurrency,
            _method: 'PUT',
            order_items: orderItems.map(item => ({
                product_id: item.product.id,
                price: item.product.price,
                quantity: item.quantity
            })),
        };
        const order_items = [orderItems.map(item => ({
            product_id: item.product.id,
            price: item.product.price,
            quantity: item.quantity
        }))];

        axios.post(process.env.REACT_APP_API_URL + '/companies/' + user.user.user.companyDetails.id + '/orders/' + orderId, orderData, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        })
            .then(response => {
                if (response.data.status) {
                    showInvoiceActionsDialog(process.env.REACT_APP_BASE_URL + 'terminal/' + response.data.data.invoiceForTemrinal, response.data.data.invoiceId);
                }

                // Handle successful order creation
                console.log('Order created successfully:', response.data);
                // You might want to reset the form and state here
            })
            .catch(error => {
                console.error('Error creating order:', error);
            });
    };

    return (
        <>
            <Container fluid>
                <Row>
                    <Col lg="12">
                        <div className="d-flex flex-wrap align-items-center justify-content-between">
                            <div className="d-flex align-items-center justify-content-between">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb p-0 mb-0">
                                        <li className="breadcrumb-item"><Link to="/orders">Orders</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">Add Order</li>
                                    </ol>
                                </nav>
                            </div>
                            <Link to="/orders" className="btn btn-primary btn-sm d-flex align-items-center justify-content-between">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
                                </svg>
                                <span className="ml-2">Back</span>
                            </Link>
                        </div>
                    </Col>
                    <Col lg="12" className="mb-3 d-flex justify-content-between">
                        <h4 className="font-weight-bold d-flex align-items-center">New Order</h4>
                    </Col>
                </Row>
                <Card>
                    <Card.Body>
                        <Row>
                            <Col lg="6">
                                <Form className="row g-3">
                                    {/* Replace the customer input field */}
                                    <div className="col-md-12 mb-3">
                                        <Form.Label htmlFor="customerSelect" className="form-label font-weight-bold text-muted text-uppercase">Customer</Form.Label>
                                        <Select
                                            classNamePrefix="my-select"
                                            className='custom-select-container'
                                            id="customerSelect"
                                            value={selectedCustomer}
                                            options={customers.map(customer => ({ value: customer.id, label: customer.name }))}
                                            onChange={handleCustomerSelect}
                                            placeholder="Search Customer"
                                        />
                                    </div>

                                    <div className="col-md-12 mb-3">
                                        <Form.Label htmlFor="Text2" className="form-label font-weight-bold text-muted text-uppercase">Phone</Form.Label>
                                        <Form.Control type="text" className="form-control"
                                            value={phone}
                                            onChange={(e) => setPhone(e.target.value)}
                                            id="Text2" placeholder="Enter Phone" />
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <Form.Label htmlFor="Text3" className="form-label font-weight-bold text-muted text-uppercase">Email</Form.Label>
                                        <Form.Control type="text" className="form-control"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            id="Text3" placeholder="Enter Email" />
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <Form.Label htmlFor="Text4" className="form-label font-weight-bold text-muted text-uppercase">Address</Form.Label>
                                        <Form.Control type="text" className="form-control"
                                            value={address}
                                            onChange={(e) => setAddress(e.target.value)}
                                            id="Text4" placeholder="Enter Address" />
                                    </div>
                                </Form>
                            </Col>
                            <Col lg="6">

                                <Form className="row g-3">
                                    <div className="col-md-12 mb-3">
                                        <Form.Label htmlFor="Text5" className="form-label font-weight-bold text-muted text-uppercase">Date</Form.Label>
                                        <Form.Control type="date" className="form-control vanila-datepicker datepicker-input" id="Text5" defaultValue={currentDate} />
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <Form.Label htmlFor="Text8" className="form-label font-weight-bold text-muted text-uppercase">Comment</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            id="Text8"
                                            rows="3"
                                            placeholder="Enter your comment"
                                            value={comment}
                                            onChange={(e) => setComment(e.target.value)}
                                        />
                                    </div>
                                </Form>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Body className="p-0">
                        <ListGroup as="ul" className="list-group-flush">
                            <ListGroup.Item as="li" className="p-3">
                                <h5 className="font-weight-bold mb-3">Order Items</h5>
                                <div className="modal-product-search d-flex">
                                    <div className="col-md-6 mb-3">
                                        <Select
                                            classNamePrefix="my-select"
                                            className='custom-select-container'
                                            id="customerSelect"
                                            value={selectedProduct}
                                            options={productsList.map(product => ({ value: product.id, label: product.name }))}
                                            onChange={selectedOption => setSelectedProduct(selectedOption)}
                                            placeholder="Search Product"
                                        />
                                    </div>
                                    <div className='col-md-6 mb-3'>
                                        <Button onClick={addItemToOrder}>Add Item</Button>
                                    </div>
                                </div>
                            </ListGroup.Item>
                            <ListGroup.Item as="li" className="p-0">
                                <div className="table-responsive">
                                    <table className="table mb-0">
                                        <thead>
                                            <tr className="text-muted">
                                                <th scope="col">Product</th>
                                                <th scope="col" className="text-center">Quantity</th>
                                                <th scope="col" className="text-center">Price</th>
                                                <th scope="col" className="text-center"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {orderItems.map((item, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        <div className="active-project-1 d-flex align-items-center mt-0 ">
                                                            <div className="h-avatar is-medium">

                                                                <img className="avatar avatar-60 rounded" alt="user-icon" src={process.env.REACT_APP_BACKEND_URL + item.product.images} />
                                                            </div>
                                                            <div className="data-content">
                                                                <div>
                                                                    <span className="font-weight-bold">{item.product.name}</span>
                                                                </div>
                                                                <p className="m-0 mt-1">
                                                                    {item.product.product_description}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        {/* Quantity input with increase and decrease buttons */}
                                                        <div className="d-flex align-items-center justify-content-center">
                                                            <button className='btn btn-default'
                                                                onClick={() =>
                                                                    handleQuantityChange(index, item.quantity - 1)
                                                                }
                                                            >
                                                                <svg xmlns="http://www.w3.org/2000/svg" className="mr-2" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                                </svg>
                                                            </button>
                                                            {item.quantity}
                                                            <button className='btn btn-default'
                                                                onClick={() =>
                                                                    handleQuantityChange(index, item.quantity + 1)
                                                                }
                                                            >
                                                                <svg xmlns="http://www.w3.org/2000/svg" className="ml-2" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                                </svg>
                                                            </button>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">{item.product.currency_name}{item.product.price}</td>
                                                    <td className="text-center">
                                                        <button className='btn btn-default' onClick={() => removeItem(index)} >
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                                            </svg>
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </ListGroup.Item>

                            <ListGroup.Item as="li" className="p-3">

                            </ListGroup.Item>
                        </ListGroup>
                        <Row>
                            <Col lg="6">
                                <div className="col-12 mb-3">
                                    <Form.Label className="form-label font-weight-bold text-muted text-uppercase">Currency</Form.Label>
                                    <Form.Control
                                        as="select"
                                        className="form-select"
                                        value={selectedCurrency}
                                        onChange={(e) => setSelectedCurrency(e.target.value)}
                                    >
                                        <option value="">Select Currency</option>
                                        {currencyOptions.map((currency) => (
                                            <option key={currency.id} value={currency.id}>
                                                {currency.name}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </div>
                                <div className="col-12 mb-3">
                                    <Form.Label className="form-label font-weight-bold text-muted text-uppercase">Total Amount</Form.Label>
                                    <Form.Control
                                        type="number"
                                        className="form-control"
                                        value={totalPrice.toFixed(2)}
                                        onChange={(e) => setTotalAmount(totalPrice.toFixed(2))}
                                        placeholder="Enter Total Amount"
                                    />
                                </div>
                                <div className="col-12 mb-3">
                                    <Form.Label className="form-label font-weight-bold text-muted text-uppercase">Discount Amount</Form.Label>
                                    <Form.Control
                                        type="number"
                                        className="form-control"
                                        value={discountAmount}
                                        onChange={(e) => {
                                            const newDiscountAmount = parseFloat(e.target.value) > 0 ? parseFloat(e.target.value) : 0;
                                            if (isNaN(newDiscountAmount) || newDiscountAmount < 0) {
                                                setDiscountAmount(0);
                                            } else {
                                                setDiscountAmount(newDiscountAmount);
                                            }
                                            const newTotalAmount = totalPrice - newDiscountAmount;
                                            setTotalAmount(newTotalAmount >= 0 ? newTotalAmount : 0);
                                            setTotalPayable(newTotalAmount);
                                        }}
                                        placeholder="Enter Discount Amount"
                                    />
                                </div>
                            </Col>
                            <Col lg="6">
                                <div className="col-12 mb-3">
                                    <Form.Label className="form-label font-weight-bold text-muted text-uppercase">Due Date</Form.Label>
                                    <Form.Control
                                        type="date"
                                        className="form-control"
                                        value={dueDate}
                                        onChange={(e) => setDueDate(e.target.value)}
                                        placeholder="Enter Due Date"
                                    />
                                </div>
                                <div className="col-12 mb-3">
                                    <Form.Label className="form-label font-weight-bold text-muted text-uppercase">Invoice Status</Form.Label>
                                    <Form.Control
                                        as="select"
                                        className="form-select"
                                        value={invoiceStatus}
                                        onChange={(e) => setInvoiceStatus(e.target.value)}
                                    >
                                        <option value="">Select Status</option>
                                        <option value="draft">Draft</option>
                                        <option value="issued">Issued</option>
                                        <option value="paid">Paid</option>
                                        <option value="overdue">Overdue</option>
                                        <option value="canceled">Canceled</option>
                                    </Form.Control>
                                </div>
                                <div className="col-12 mb-3">
                                    <Form.Label className="form-label font-weight-bold text-muted text-uppercase">Payable Amount</Form.Label>
                                    <Form.Control
                                        type="number"
                                        className="form-control"
                                        value={totalPayable > 0 ? totalPayable : totalPrice.toFixed(2)}
                                        onChange={(e) => setTotalPayable(parseFloat(e.target.value))}
                                        placeholder="Enter Total Amount"
                                    />
                                </div>
                                <div className="col-12 d-flex justify-content-end mb-3">
                                    <Button variant="btn btn-primary ml-5" onClick={createOrder}>update Order</Button>
                                </div>
                            </Col>
                        </Row>

                    </Card.Body>
                </Card>
            </Container>
        </>
    )
}

export default Orderedit;