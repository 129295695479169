import React, { Component } from 'react';
import { Container, Row, Col, Form, Button, OverlayTrigger, Tooltip, Spinner } from 'react-bootstrap'
import Card from '../../../components/Card'
import axios from 'axios';
import $ from 'jquery'; // Import jQuery for DataTables
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import 'datatables.net';
import 'datatables.net-bs4/js/dataTables.bootstrap4.min.js';
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import './tablesearch.css';

class Companies extends Component {
    constructor(props) {
        super(props);
        this.state = {
            companies: [],
            isLoading: true,
            isModalOpen: false,
            selectedCompany: null,
            searchQuery: '',
        };
        this.handleDelete = this.handleDelete.bind(this);
    }

    componentDidMount() {
        this.fetchCompanies();
    }

    fetchCompanies() {
        console.log(this.props);
        const token = this.props.user.user.user.token;
        axios.get(process.env.REACT_APP_API_URL + '/company', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then((response) => {
                const companies = response.data.data; // Assuming your API returns data in a 'data' property
                this.setState({ isLoading: false });
                this.setState({ companies }, () => {
                    $(this.dataTable).DataTable();
                });
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }
    handleDelete = (e,companyId) => {
        e.preventDefault();
        Swal.fire({
            title: 'Delete Company',
            text: 'Are you sure you want to delete this company?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, delete it'
        }).then((result) => {
            if (result.isConfirmed) {
                const token = this.props.user.user.user.token;
                axios.delete(process.env.REACT_APP_API_URL + '/company/' + companyId, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                    .then((response) => {
                        if (response.data.status) {

                            Swal.fire('Done!', response.data.message, 'success').then(() => {
                                this.setState(prevState => ({
                                    companies: prevState.companies.filter(company => company.id !== companyId)
                                }));
                            });
                        }
                        else {
                            Swal.fire('Ooopsss!', response.data.message, 'error');
                        }
                    })
                    .catch((error) => {
                        Swal.fire('Ooopsss!', error.response.data.message, 'error');
                    });
            }
        });
    }
    handleEdit = (company) => {
        this.setState({
            isModalOpen: true,
            selectedCompany: company,
        });
    };

    handleModalClose = () => {
        this.setState({
            isModalOpen: false,
            selectedCompany: null,
        });
    };

    handleModalSave = (updatedCompany) => {
        const { companies } = this.state;

        // Find the index of the company being updated
        const index = companies.findIndex(company => company.id === updatedCompany.id);

        if (index !== -1) {
            // Update the company data in the array
            const updatedCompanies = [...companies];
            updatedCompanies[index] = updatedCompany;

            // Update the state with the modified array
            this.setState({ companies: updatedCompanies });
        }

        // Close the modal or perform any other necessary actions
        this.handleModalClose();

    }
    render() {
        const { companies, isLoading } = this.state;
        const { isModalOpen, selectedCompany } = this.state;
        const token = this.props.user.token
        return (
            <Container fluid>
                <Row>
                    <Col lg="12">
                        <div className="d-flex flex-wrap align-items-center justify-content-between my-schedule mb-4">
                            <div className="d-flex align-items-center justify-content-between">
                                <h4 className="font-weight-bold">Companies</h4>
                            </div>
                            <div className="create-workform">
                                <div className="d-flex flex-wrap align-items-center justify-content-between">
                                    <div className="modal-product-search d-flex">
                                       
                                        <Link to="/companies-add" className="btn btn-primary position-relative d-flex align-items-center justify-content-between">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="mr-2" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                            </svg>
                                            Add Company
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Row>
                            <Col lg="12">
                                <Card className="card-block card-stretch">
                                    <Card.Body className="p-0">
                                        <div className="d-flex justify-content-between align-items-center p-3">
                                            <h5 className="font-weight-bold">Company List</h5>

                                        </div>
                                        <div className="table-responsive">
                                            {isLoading ? (
                                                <div className="text-center mb-5">
                                                    <Spinner animation="border" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </Spinner>
                                                </div>
                                            ) : (
                                                <table ref={(dataTable) => {
                                                    this.dataTable = dataTable;
                                                }}
                                                    className="table data-table mb-0">
                                                    <thead className="table-color-heading">
                                                        <tr className="">
                                                            <th scope="col">
                                                                Id
                                                            </th>
                                                            <th scope="col">
                                                                Name
                                                            </th>
                                                            <th scope="col">
                                                                Email
                                                            </th>
                                                            <th scope="col">
                                                                Phone
                                                            </th>
                                                            <th scope="col">
                                                                Theme Color
                                                            </th>
                                                            <th scope="col">
                                                                Logo
                                                            </th>
                                                            <th scope="col" className="text-right">
                                                                Action
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {companies
                                                            .filter(item =>
                                                                item.name.toLowerCase().includes(this.state.searchQuery.toLowerCase()) ||
                                                                item.email.toLowerCase().includes(this.state.searchQuery.toLowerCase()) ||
                                                                item.phone.includes(this.state.searchQuery)
                                                            )
                                                            .map((item) => (
                                                                <tr key={item.id} className="white-space-no-wrap">
                                                                    <td>{item.id}</td>
                                                                    <td className="">
                                                                        <div className="active-project-1 d-flex align-items-center mt-0 ">

                                                                            <div className="data-content">
                                                                                <div>
                                                                                    <span className="font-weight-bold">{item.name}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td>{item.email}</td>
                                                                    <td>{item.phone}</td>
                                                                    <td>
                                                                        {item.color_them && (
                                                                            <div className="alert text-white" role="alert" style={{ backgroundColor: item.color_them }}>
                                                                                <div className="iq-alert-text">
                                                                                    {item.color_them}
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </td>
                                                                    <td>
                                                                        {item.logo ? (
                                                                            <div className="active-project-1 d-flex align-items-center mt-0 ">
                                                                                <div className="h-avatar is-medium">
                                                                                    <img src={process.env.REACT_APP_BACKEND_URL + item.logo} alt="Company Logo" className="avatar rounded-circle" />
                                                                                </div>

                                                                            </div>

                                                                        ) : (
                                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
                                                                            </svg>

                                                                        )}

                                                                    </td>
                                                                    <td>
                                                                        <div className="d-flex justify-content-end align-items-center">
                                                                            <OverlayTrigger placement="top" overlay={<Tooltip>View</Tooltip>} >
                                                                                <Link className="" to={`/company-view/${item.id}`}>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" className="text-secondary" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                                                                    </svg>
                                                                                </Link>
                                                                            </OverlayTrigger>
                                                                            <OverlayTrigger placement="top" overlay={<Tooltip>Edit</Tooltip>} >
                                                                                <Link className="" to={`/company-edit/${item.id}`}>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" className="text-secondary mx-4" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                                                                                    </svg>
                                                                                </Link>
                                                                            </OverlayTrigger>
                                                                            <OverlayTrigger placement="top" overlay={<Tooltip>Delete</Tooltip>} >
                                                                                <a className="badge bg-danger" href="#" onClick={(e) => this.handleDelete(e,item.id)}>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                                                                    </svg>
                                                                                </a>
                                                                            </OverlayTrigger>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                    </tbody>
                                                </table>
                                            )}
                                        </div>

                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>

        );
    }
}

export default Companies;
