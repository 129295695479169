import React from 'react'
import { Link } from 'react-router-dom'
import { Navbar, Nav, Dropdown, Button, Form } from 'react-bootstrap'
import Card from '../../../../components/Card'
import { getDarkMode } from '../../../../store/mode'
import { connect } from "react-redux";
import { logoutUser } from '../../../../store/actions';
import { useHistory } from 'react-router-dom';


//img

import user1 from '../../../../assets/images/user/1.jpg'
import user2 from '../../../../assets/images/user/2.jpg'

// flag
import flag1 from '../../../../assets/images/Flag/flag001.png'
import flag3 from '../../../../assets/images/Flag/flag-03.png'
import flag4 from '../../../../assets/images/Flag/flag-04.png'
import flag2 from '../../../../assets/images/Flag/flag-02.png'
import flag5 from '../../../../assets/images/Flag/flag-05.png'
import flag6 from '../../../../assets/images/Flag/flag-06.png'


//chnage-mode
import ChangeMode from '../../../Change-Mode'
function mapStateToProps(state) {
   
    return {
        darkMode: getDarkMode(state)
    };
}


const HeaderStyle1 = (props) => {
    
    let history = useHistory()
    const minisidbar = () => {
        document.body.classList.toggle('sidebar-main')
    }
    const handleLogout = () => {
        props.logoutUser(); // Dispatch logout action
        // Redirect user to login page after logout
        history.push('/');
    };

    return (
        <>
            <div className="iq-top-navbar">
                <div className="iq-navbar-custom">
                    <Navbar expand="lg" className="navbar-light p-0">
                        <div className="side-menu-bt-sidebar" onClick={minisidbar}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="text-secondary wrapper-menu" width="30" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                            </svg>
                        </div>
                        <div className="d-flex align-items-center">
                            <ChangeMode />
                            <Navbar.Toggle aria-controls="navbarSupportedContent">
                                <svg xmlns="http://www.w3.org/2000/svg" className="text-secondary" width="30" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
                                </svg>
                            </Navbar.Toggle>
                            <Navbar.Collapse id="navbarSupportedContent">
                                <Nav as="ul" className="ml-auto navbar-list align-items-center">

                                    <Dropdown as="li" className="nav-item nav-icon">
                                        <Dropdown.Toggle as={Button} href="#" variant="nav-item nav-icon pr-0 search-toggle" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                                            <img src={process.env.REACT_APP_BACKEND_URL + props.user.user.user.image} className="img-fluid avatar-rounded" alt="user" />
                                            <span className="mb-0 ml-2 user-name">{props.user.user.user.name}</span>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu as="ul" className="dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                            <Dropdown.Item as="li" className="d-flex svg-icon">
                                                <svg className="svg-icon mr-0 text-secondary" id="h-01-p" width="20" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                </svg>
                                                <Link to="/user-profile">My Profile</Link>
                                            </Dropdown.Item>
                                            <Dropdown.Item as="li" className="d-flex svg-icon">
                                                <svg className="svg-icon mr-0 text-secondary" id="h-02-p" width="20" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                                                </svg>
                                                <Link to="/user-profile-edit">Edit Profile</Link>
                                            </Dropdown.Item>
                                            <Dropdown.Item as="li" className="d-flex svg-icon border-top">
                                                <svg className="svg-icon mr-0 text-secondary" id="h-05-p" width="20" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                                                </svg>
                                                <a href="#" onClick={handleLogout}>Logout</a>
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Nav>
                            </Navbar.Collapse>
                        </div>
                    </Navbar>
                </div>
            </div>


        </>
    )
}


const mapDispatchToProps = {
    logoutUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderStyle1);