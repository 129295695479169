import React from 'react'
import { Link } from 'react-router-dom'
import {Container,Col,Row }from 'react-bootstrap'

const FooterStyle = () =>{
    return(
        <>
            <footer className="mm-footer">
                <Container fluid>
                   
                </Container>
            </footer>   
        </>
    )
}

export default FooterStyle