import React from 'react';

// Partials
import HeaderStyle1 from '../../components/partials/backend/HeaderStyle/HeaderStyle1';
import SidebarStyle from '../../components/partials/backend/SidebarStyle/SidebarStyle'
import FooterStyle from '../../components/partials/backend/FooterStyle/FooterStyle'


// Router Component
import Layout1Route from '../../router/layout1-route'


const Layout1 = (user) => {
    
    return(
        <>
         <div className="wrapper">
            <HeaderStyle1 user={user} />
            <SidebarStyle user={user} />
            <div className="content-page">
                <Layout1Route user={user} />
            </div>
         </div>
         <FooterStyle />
        </>
    )
}


export default Layout1;