import React, { useEffect, useState } from 'react'
import { Link, useLocation, useHistory } from 'react-router-dom'
import { Accordion, Button } from 'react-bootstrap'
import Scrollbar from 'smooth-scrollbar'
import { connect } from "react-redux";
import { getDarkMode } from '../../../../store/mode'

//img
import logo from '../../../../../src/assets/images/logo.png'

function mapStateToProps(state) {
    return {
        darkMode: getDarkMode(state)
    };
}


const minisidbar = () => {
    document.body.classList.toggle('sidebar-main')
}


const SidebarStyle = (props) => {

    //location
    let location = useLocation();
    let history = useHistory();
    const urlParams = new URLSearchParams(window.location.search);
    const sidebar = urlParams.get('sidebar');
    var variant = '';
    if (sidebar !== null) {
        variant = '';
        switch (sidebar) {
            case "0":
                variant = 'sidebar-dark';
                break;
            case "1":
                variant = 'sidebar-light';
                break;
            default:
                variant = '';
                break;
        }
    }
    const sidebarStyle = {
        backgroundColor: props.user.user.companyDetails.color_them
    };
    // Collapse state
    const [activeMenu, setActiveMenu] = useState(false)
    const [activesubMenu, setSubmenu] = useState(false)
    useEffect(
        () => {
            Scrollbar.init(document.querySelector('#my-scrollbar'))
        }
    )

    const handleDelayedNavigation = (to) => {
        setTimeout(() => {
            history.push(to)
        }, 500); // Delay in milliseconds
    };


    return (
        <>
            <div className={`iq-sidebar sidebar-default ${variant}`} style={sidebarStyle}>
                <div className="iq-sidebar-logo d-flex align-items-end justify-content-between">
                    <Link to="/" className="header-logo">
                        <img src={process.env.REACT_APP_BACKEND_URL + props.user.user.companyDetails.logo} className={`img-fluid rounded-normal light-logo ${props.darkMode ? 'd-none' : ''}`} alt="logo" />
                        <span>{props.user.user.companyDetails.name}</span>
                    </Link>
                    <div className="side-menu-bt-sidebar-1">
                        <svg onClick={minisidbar} xmlns="http://www.w3.org/2000/svg" className="text-light wrapper-menu" width="30" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </div>
                </div>
                <div className="data-scrollbar" data-scroll="1" id="my-scrollbar">
                    <nav className="iq-sidebar-menu">
                        <Accordion as="ul" id="iq-sidebar-toggle" className="side-menu" onSelect={(e) => setActiveMenu(e)}>
                            <li className={`${location.pathname === '/' ? 'active' : ''}  sidebar-layout`}>
                                <Link onClick={() => handleDelayedNavigation('/dashboard')} to="" className="svg-icon">
                                    <i className="">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
                                        </svg>
                                    </i>
                                    <span className="ml-2">Dashboard</span>
                                </Link>
                            </li>
                            <li className="px-3 pt-3 pb-2 ">
                                <span className="text-uppercase small font-weight-bold">Application</span>
                            </li>
                            <li className={`${location.pathname === '/customers' ? 'active' : ''}  sidebar-layout`}>
                                <Link onClick={() => handleDelayedNavigation('/customers')} to="" className="svg-icon ">
                                    <i className="">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                                        </svg>
                                    </i>
                                    <span className="ml-2">Customers</span>
                                </Link>
                            </li>
                            <li className={`${location.pathname === '/products' ? 'active' : ''}  sidebar-layout`} >
                                <Link onClick={() => handleDelayedNavigation('/products')} to="" className="svg-icon">
                                    <i className="">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z" />
                                        </svg>
                                    </i>
                                    <span className="ml-2">Products</span>
                                </Link>
                            </li>
                            <li className={`${location.pathname === '/categories' ? 'active' : ''}  sidebar-layout`} >
                                <Link onClick={() => handleDelayedNavigation('/categories')} to="" className="svg-icon">
                                    <i className="">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zM3.75 12h.007v.008H3.75V12zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm-.375 5.25h.007v.008H3.75v-.008zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
                                        </svg>


                                    </i>
                                    <span className="ml-2">Categories</span>
                                </Link>
                            </li>
                            <li className={`${location.pathname === '/orders' ? 'active' : ''}  sidebar-layout`} >
                                <Link onClick={() => handleDelayedNavigation('/orders')} to="" className="svg-icon">
                                    <i className="">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" />
                                        </svg>
                                    </i>
                                    <span className="ml-2">Orders</span>
                                </Link>
                            </li>
                            <li className={`${location.pathname === '/invoices' ? 'active' : ''}  sidebar-layout`}>
                                <Link onClick={() => handleDelayedNavigation('/invoices')} to="" className="svg-icon">
                                    <i className="">
                                        <svg className="icon line" width="18" id="receipt" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke="currentColor">
                                            <path d="M17,16V3L13,5,10,3,7,5,3,3V17.83A3.13,3.13,0,0,0,5.84,21,3,3,0,0,0,9,18V17a1,1,0,0,1,1-1H20a1,1,0,0,1,1,1v1a3,3,0,0,1-3,3H6" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <line x1="8" y1="10" x2="12" y2="10" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" ></line>
                                        </svg>
                                    </i>
                                    <span className="ml-2">Invoices</span>
                                </Link>
                            </li>
                            <li className={`${location.pathname === '/transactions' ? 'active' : ''}  sidebar-layout`}>
                                <Link onClick={() => handleDelayedNavigation('/transactions')} to="" className="svg-icon">
                                    <i className="">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z" />
                                        </svg>


                                    </i><span className="ml-2">Transactions</span>
                                </Link>
                            </li>
                            <li className={`${location.pathname === '/companies' ? 'active' : ''}  sidebar-layout`}>
                                {props.user.user.user.is_admin ? (
                                    <Link onClick={() => handleDelayedNavigation('/companies')} to="" className="svg-icon">
                                        <i className="">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008z" />
                                            </svg>
                                        </i><span className="ml-2">Companies</span>
                                    </Link>
                                ) : null} {/* If admin is not logged in, render nothing */}
                            </li>

                        </Accordion>
                    </nav>
                    <div className="pt-5 pb-5"></div>
                </div>
            </div>
        </>
    )
}

// export default SidebarStyle;
export default connect(mapStateToProps)(SidebarStyle)