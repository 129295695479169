import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Form, Button, ListGroup, Spinner, Alert } from 'react-bootstrap';
import Card from './../components/Card'
import { Link, useParams } from 'react-router-dom'
import axios from 'axios'; // Import Axios
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';

const Terminal = () => {
    const history = useHistory();
    const { invoiceId } = useParams();
    const [invoiceData, setInvoiceData] = useState([]);
    const [orderData, setOrderData] = useState({});
    const [orderItemsData, setOrderItemsData] = useState([]);
    const [customerData, setCustomerData] = useState({});
    const [customerCountryData, setCustomerCountryData] = useState({});
    const [customerStateData, setCustomerStateData] = useState({});
    const [companyData, setCompanyData] = useState({});
    const [currencyData, setCurrencyData] = useState({});
    const [cardNumber, setCardNumber] = useState('');
    const [cvv, setCvv] = useState('');
    const [expiryDate, setExpiryDate] = useState('');
    const [cardNumberError, setCardNumberError] = useState('');
    const [cvvError, setCvvError] = useState('');
    const [expiryDateError, setExpiryDateError] = useState('');
    const [isProcessingPayment, setIsProcessingPayment] = useState(false);
    const [isPaymentSuccessful, setPaymentSuccessful] = useState(false);

    useEffect(() => {
        checkInvoiceFormat();
        fetchInvoiceData();
    }, []);
    const checkInvoiceFormat = () => {
        if (invoiceId) {
            const decodedInvoiceNumber = atob(invoiceId);
            if (!decodedInvoiceNumber.startsWith("INV-")) {
                history.push('/not-found');
            }
        } else {
            history.push('/not-found');
        }

    }
    const fetchInvoiceData = () => {
        axios.get(process.env.REACT_APP_API_URL + '/invoice/' + invoiceId)
            .then((response) => {
                const fetchedInvoiceData = response.data;
                if (fetchedInvoiceData.status) {
                    setInvoiceData(fetchedInvoiceData.invoiceData);
                    setCompanyData(fetchedInvoiceData.invoiceData.company);
                    setOrderData(fetchedInvoiceData.invoiceData.order);
                    setOrderItemsData(fetchedInvoiceData.invoiceData.order.order_items);
                    setCustomerData(fetchedInvoiceData.invoiceData.customer);
                    setCustomerCountryData(fetchedInvoiceData.invoiceData.customer.country);
                    setCustomerStateData(fetchedInvoiceData.invoiceData.customer.state);
                    setCurrencyData(fetchedInvoiceData.invoiceData.currency);

                } else {
                    history.push('/not-found');
                }
            })
            .catch((error) => {
                history.push('/not-found');
            });
    }

    const validateCardNumber = () => {
        const errors = {};
        if (!cardNumber || !/^[0-9]{16}$/.test(cardNumber)) {
            errors.cardNumber = 'Invalid card number. It must be 16 digits.';
        }

        setCardNumberError(errors.cardNumber || '');
        return Object.keys(errors).length === 0;
    };

    const validateCvv = () => {
        const errors = {};

        if (!cvv || !/^[0-9]{3}$/.test(cvv)) {
            errors.cvv = 'Invalid CVV. It must be a 3-digit number.';
        }

        setCvvError(errors.cvv || '');
        return Object.keys(errors).length === 0;
    };

    const validateExpiryDate = () => {
        const errors = {};

        if (!expiryDate || !/^(0[1-9]|1[0-2])\/\d{2}$/.test(expiryDate)) {
            errors.expiryDate = 'Invalid expiry date. It must be in the format MM/YY.';
        }

        setExpiryDateError(errors.expiryDate || '');
        return Object.keys(errors).length === 0;
    };


    function formatDate(inputDate) {
        const options = {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
        };

        const date = new Date(inputDate);
        const formattedDate = date.toLocaleDateString('en-US', options);

        // Replace 'st', 'nd', 'rd', 'th' for the day part
        const dayWithSuffix = formattedDate.replace(
            /(\d{1,2})(st|nd|rd|th)/,
            (match, day, suffix) => {
                return day + (suffix === 'st' ? 'st' : suffix === 'nd' ? 'nd' : suffix === 'rd' ? 'rd' : 'th');
            }
        );

        return dayWithSuffix;
    }

    const handlePayment = (e) => {
        e.preventDefault();
        const isCardNumberValid = validateCardNumber();
        const isCvvValid = validateCvv();
        const isExpiryDateValid = validateExpiryDate();

        if (isCardNumberValid && isCvvValid && isExpiryDateValid) {
            setIsProcessingPayment(true);
            const paymentData = {
                customerId: customerData.id,
                invoiceId: invoiceData.id,
                cardNumber: btoa(cardNumber),
                cvv: btoa(cvv),
                expiryDate: expiryDate
            }
            axios.post(process.env.REACT_APP_API_URL + '/payment', paymentData)
                .then((response) => {
                    setIsProcessingPayment(false);
                    const fetchedPaymentData = response.data;
                    if (fetchedPaymentData.status) {
                        Swal.fire('Done!', response.data.message, 'success').then(() => {
                            setPaymentSuccessful(true);
                            setCardNumber('');
                            setCvv('');
                            setExpiryDate('');
                        });
                    } else {
                        // Handle payment failure, if needed
                        Swal.fire('Ooopsss!', response.data.message, 'error');
                    }
                })
                .catch((error) => {
                    setIsProcessingPayment(false);
                    // Handle any error that occurs during the payment request
                    Swal.fire('Ooopsss!', error.message, 'error');

                });
        }
    }

    return (
        <>
            <Container fluid>
                <Row>
                    <Col lg="12">
                        <div className="d-flex flex-wrap align-items-center justify-content-between mb-4">

                        </div>
                    </Col>
                    <Col lg="12">
                        <Card>
                            <Card.Body>
                                <Row className="pb-4 mx-0 card-header-border">
                                    <Col lg="12" className="mb-3">
                                        <img className="avatar avatar-50 is-squared" alt="user-icon" src={process.env.REACT_APP_BACKEND_URL + companyData.logo} />
                                    </Col>
                                    <Col lg="6">
                                        <div className="text-left">
                                            <h5 className="font-weight-bold mb-2">Invoice number</h5>
                                            <p className="mb-0">{invoiceData.invoice_number}</p>
                                        </div>
                                    </Col>
                                    <Col lg="6">
                                        <div className="text-right">
                                            <h5 className="font-weight-bold mb-2">Invoice Date</h5>
                                            <p className="mb-0">{formatDate(invoiceData.invoice_date)}</p>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="pt-4 pb-5 mx-0">
                                    <Col lg="6">
                                        <div className="text-left">
                                            <h5 className="font-weight-bold mb-3">Invoice From</h5>
                                            <p className="mb-0 mb-1">{companyData.name}</p>
                                            <p className="mb-0 mb-2">{companyData.email}</p>
                                        </div>
                                    </Col>
                                    <Col lg="6">
                                        <div className="text-right">
                                            <h5 className="font-weight-bold mb-3">Invoice To</h5>
                                            <p className="mb-0 mb-1">{customerData.name}</p>
                                            <p className="mb-0 mb-1">{customerData.address}</p>
                                            <p className="mb-0 mb-1">{customerStateData.name}</p>
                                            <p className="mb-0 mb-1">{customerData.zipcode}</p>
                                            <p className="mb-0 mb-2">{customerCountryData.name}</p>
                                            <p className="mb-0 mb-2">info@blueberry.com</p>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="12">
                                        <ListGroup as="ul" className="list-group-flush">
                                            <ListGroup.Item as="li" className="p-0">
                                                <div className="table-responsive">
                                                    <table className="table table-bordered mb-0">
                                                        <thead>
                                                            <tr className="text-muted">
                                                                <th scope="col" className="text-left">No</th>
                                                                <th scope="col">Name</th>
                                                                <th scope="col">Description</th>
                                                                <th scope="col" className="text-right">Quantity</th>
                                                                <th scope="col" className="text-right">Price</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                orderItemsData.map((item) => (

                                                                    <tr key={item.id}>
                                                                        <td className="text-left">
                                                                            1
                                                                        </td>
                                                                        <td>
                                                                            {item.product.name}
                                                                        </td>
                                                                        <td>
                                                                            {item.product.product_description}
                                                                        </td>
                                                                        <td className="text-right">
                                                                            {item.quantity}
                                                                        </td>
                                                                        <td className="text-right">
                                                                            {currencyData.symbol}{item.price}
                                                                        </td>

                                                                    </tr>
                                                                ))
                                                            }

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </ListGroup.Item>
                                            <ListGroup.Item as="li">
                                                <div className="d-flex justify-content-end mb-2">
                                                    Subtotal: <p className="ml-2 mb-0">{currencyData.symbol}{invoiceData.total_amount}</p>
                                                </div>
                                                <div className="d-flex justify-content-end mb-2">
                                                    Discount: <p className="ml-2 mb-0">{currencyData.symbol}{invoiceData.discount_amount}</p>
                                                </div>
                                                <div className="d-flex justify-content-end mb-2">
                                                    Total: <p className="ml-2 mb-0 font-weight-bold">{currencyData.symbol}{invoiceData.paid}</p>
                                                </div>

                                            </ListGroup.Item>
                                        </ListGroup>
                                    </Col>
                                    {isPaymentSuccessful ? (
                                        <Alert variant="success">Payment successful!, Invoice has been paid!</Alert>
                                    ) : (
                                        <Row>
                                            <Col lg="6">
                                                <div className="col-md-12 mb-3">
                                                    <Form.Label htmlFor="Text2" className="form-label font-weight-bold text-muted text-uppercase">Card Number</Form.Label>
                                                    <Form.Control type="text" className="form-control"
                                                        name="cardNumber"
                                                        value={cardNumber}
                                                        onChange={(e) => setCardNumber(e.target.value)}
                                                        onBlur={validateCardNumber}
                                                        placeholder="Enter card number" />
                                                    <span className="text-danger">{cardNumberError}</span>
                                                </div>

                                                <div className="col-md-12 mb-3">
                                                    <Form.Label htmlFor="Text2" className="form-label font-weight-bold text-muted text-uppercase">CVV</Form.Label>
                                                    <Form.Control type="text" className="form-control"
                                                        name="cvv"
                                                        value={cvv}
                                                        onChange={(e) => setCvv(e.target.value)}
                                                        onBlur={validateCvv}
                                                        placeholder="CVV" />
                                                    <span className="text-danger">{cvvError}</span>
                                                </div>
                                            </Col>
                                            <Col lg="6">
                                                <div className="col-md-12 mb-3">
                                                    <Form.Label htmlFor="Text2" className="form-label font-weight-bold text-muted text-uppercase">Expiry Date</Form.Label>
                                                    <Form.Control type="text" className="form-control"
                                                        name="expiryDate"
                                                        value={expiryDate}
                                                        onChange={(e) => setExpiryDate(e.target.value)}
                                                        onBlur={validateExpiryDate}
                                                        placeholder="MM/YY" />
                                                    <span className="text-danger">{expiryDateError}</span>
                                                </div>
                                            </Col>
                                        </Row>
                                    )}
                                    <Col lg="12">
                                        <div className="d-flex flex-wrap justify-content-between align-items-center p-4">
                                            <div className="flex align-items-start flex-column">
                                                <h6>Notes</h6>
                                                <p className="mb-0 my-2">{orderData.comments}</p>
                                            </div>
                                            <div className="flex align-items-start flex-column">
                                                {isPaymentSuccessful ? (
                                                    ''
                                                ) : (<Button
                                                    variant="btn btn-primary px-4"
                                                    onClick={handlePayment}
                                                    disabled={isProcessingPayment} // Disable button while processing
                                                >
                                                    {isProcessingPayment ? (
                                                        <>
                                                            <Spinner animation="border" size="sm" role="status" />
                                                            <span className="ml-2">Processing...</span>
                                                        </>
                                                    ) : (
                                                        'Pay Now'
                                                    )}
                                                </Button>)}
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>

        </>
    )
}

export default Terminal;