import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Button, ListGroup, Tab, Nav } from 'react-bootstrap'
import Card from '../../../components/Card'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router-dom';
import axios from 'axios'; // Import Axios
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
// img
import user1 from '../../../assets/images/user/1.jpg'


const Customerview = ({ user }) => {
    let history = useHistory()
    const { customerId } = useParams();
    const token = user.user.user.token
    const [customerData, setCustomerData] = useState({});
    const [customerInvoicesData, setCustomerInvoicesData] = useState([]);
    const [invoiceDate, setInvoiceDate] = useState({});
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_URL}/companies/${user.user.user.companyDetails.id}/customers/${customerId}`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                setCustomerData(response.data.customersData);
                setCustomerInvoicesData(response.data.customerInvoices);

            } catch (error) {
                console.error('Error fetching customer details:', error);
            }
        };

        fetchData();
    }, [customerId, token, user.user.user.companyDetails.id]);

    const handleDateFormat = (date) => {
        const dateTimeString = date;
        const dateTime = new Date(dateTimeString);

        // Convert to a human-readable format
        const formattedDate = dateTime.toLocaleDateString(); // e.g. "8/26/2023"
        const formattedTime = dateTime.toLocaleTimeString(); // e.g. "12:00:00 AM"

        // Combine date and time
        const formattedDateTime = `${formattedDate} ${formattedTime}`;
        return formattedDateTime;
    }
    const getStatusColor = (status) => {

        switch (status) {

            case 'draft':
                return '#999999'; // Gray for draft status
            case 'issued':
                return '#ffeb3b'; // Orange for issued status
            case 'paid':
                return '#3cb72c'; // Green for paid status
            case 'overdue':
                return '#ff5722'; // Red for overdue status
            case 'canceled':
                return '#f44336'; // Red for canceled status
            default:
                return '#000000'; // Default color if status doesn't match any condition
        }
    };
    return (
        <>
            <Container fluid>
                <Row>
                    <Col lg="12">
                        <div className="d-flex flex-wrap align-items-center justify-content-between">
                            <div className="d-flex align-items-center justify-content-between">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb p-0 mb-0">
                                        <li className="breadcrumb-item"><Link to="/customers">Customers</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">Customer View</li>
                                    </ol>
                                </nav>
                            </div>
                            <Link to="/customers" className="btn btn-primary btn-sm d-flex align-items-center justify-content-between ml-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
                                </svg>
                                <span className="ml-2">Back</span>
                            </Link>
                        </div>
                    </Col>
                    <Col lg="12" className="mb-3 d-flex justify-content-between">
                        <h4 className="font-weight-bold d-flex align-items-center">Customer View</h4>
                    </Col>
                </Row>

                <Row>
                    <Col lg="4">
                        <Card>
                            <ListGroup as="ul" className="list-group-flush">
                                <ListGroup.Item as="li">
                                    <div>
                                        <ListGroup as="ul" className="list-style-1 mb-0">
                                            <ListGroup.Item as="li" className="d-flex justify-content-start align-items-center">

                                                <div className="list-style-detail ml-4 mr-2">
                                                    <h5 className="font-weight-bold">{customerData.name}</h5>
                                                </div>
                                            </ListGroup.Item>
                                        </ListGroup>
                                    </div>
                                    <Row className="mt-3">

                                        <Col xs="12" className="text-center">
                                            <Link className="btn btn-block btn-sm btn-secondary" variant="" to={`/customers-edit/${customerData.id}`}>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                                                </svg>
                                                Edit Profile
                                            </Link>
                                        </Col>
                                    </Row>
                                </ListGroup.Item>
                                <ListGroup.Item as="li">
                                    <table className="table table-borderless mb-0">
                                        <tbody>
                                            <tr>
                                                <td className="p-0">
                                                    <p className="mb-0 text-muted">Email ID</p>
                                                </td>
                                                <td>
                                                    <p className="mb-0 ">{customerData.email}</p>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td className="p-0">
                                                    <p className="mb-0 text-muted">Phone</p>
                                                </td>
                                                <td>
                                                    <p className="mb-0 ">{customerData.phone}</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="p-0">
                                                    <p className="mb-0 text-muted">Country</p>
                                                </td>
                                                <td>
                                                    <p className="mb-0 ">{customerData.country}</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="p-0">
                                                    <p className="mb-0 text-muted">State/Region</p>
                                                </td>
                                                <td>
                                                    <p className="mb-0 ">{customerData.state}</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="p-0">
                                                    <p className="mb-0 text-muted">Address</p>
                                                </td>
                                                <td>
                                                    <p className="mb-0 ">{customerData.address}</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="p-0">
                                                    <p className="mb-0 text-muted">Company</p>
                                                </td>
                                                <td>
                                                    <p className="mb-0 ">{customerData.company}</p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </ListGroup.Item>

                            </ListGroup>
                        </Card>
                    </Col>
                    <Col lg="8">
                        <Card>
                            <Card.Body className="p-0">
                                <Tab.Container defaultActiveKey="first">
                                    <Nav as="ul" variant="nav tab-nav-pane nav-tabs pt-2 mb-0">
                                        <Nav.Item as="li" className="pb-2 mb-0 nav-item"><Nav.Link data-toggle="tab" eventKey="first" bsPrefix="font-weight-bold text-uppercase px-5 py-2 " >Invoices</Nav.Link></Nav.Item>

                                    </Nav>

                                    <Tab.Content>
                                        <Tab.Pane eventKey="first" className=" fade show  ">
                                            <div className="d-flex justify-content-between align-items-center p-3">
                                                <h5>Invoice List</h5>
                                                <Link to="/order-new" className="btn btn-secondary btn-sm">
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="mr-2" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                                    </svg>
                                                    Add New
                                                </Link>
                                            </div>
                                            <div className="table-responsive">
                                                <table className="table data-table mb-0">
                                                    <thead className="table-color-heading">
                                                        <tr className="text-muted">
                                                            <th scope="col">ID</th>
                                                            <th scope="col">Invoice Number</th>
                                                            <th scope="col">Due Date </th>
                                                            <th scope="col">Status</th>
                                                            <th scope="col" className="text-right">Total</th>
                                                            <th scope="col" >Created Date</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {customerInvoicesData.map((invoice) => (

                                                            <tr key={invoice.id}>
                                                                <td>{invoice.id}</td>
                                                                <td>{invoice.invoice_number}</td>
                                                                <td>{invoice.invoice_date}</td>
                                                                <td>
                                                                    <p className={`mb-0 d-flex justify-content-start align-items-center`}>
                                                                        <svg className="mr-2" xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 24 24" fill="none">
                                                                            <circle cx="12" cy="12" r="8" fill={getStatusColor(invoice.invoice_status)}></circle>
                                                                        </svg>
                                                                        {invoice.invoice_status}
                                                                    </p>
                                                                </td>
                                                                <td className="text-right">${invoice.total_amount}</td>
                                                                <td>{handleDateFormat(invoice.created_at)}</td>

                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Tab.Pane>

                                    </Tab.Content>
                                </Tab.Container>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
export default Customerview;